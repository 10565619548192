import React, { useEffect, useState } from 'react';
import './Service.css';
import headerimg1 from './Serviceheader1.png';
import headerimg2 from './Serviceheader2.png';
import ServiceWork from './servicesimg/ServiceWork.png'
import { ServiceData, ServiceIcon } from './ServiceData';
import Cookies from 'js-cookie'
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';

const Service = () => {
    const [isLogIn, setIsLogIn] = useState(false);

    useEffect(() => {
        const token = Cookies.get('parcelToken');
        if (token) {
            setIsLogIn(true);
        } else {
            setIsLogIn(false);
        }
    }, []);
    return (
        <div className="service-container">
            <ScrollToTop/>
            <div className="header">
                <div className='img-block'>
                    <div className='img-header1' >
                        <img src={headerimg1} alt="Delivery" className="image1" draggable='false' />
                    </div>
                    <div className='img-header2' >
                        <img src={headerimg2} alt="Delivery" className="image2" draggable='false' />
                        <h2>Fast, Simple and Quick Courier Service</h2>
                    </div>
                </div>
                <div className="text-block">
                    <h2>Fast, Simple and Quick Courier Service</h2>
                    <h1>You are our first priority.</h1>
                    <p>Adopting client-centric approach, we render only the best logistic experience to our business and their customers.</p>
                </div>
            </div>
            <div className="services">
                {/* <div className="service-list">
                    <div className="service-item">
                        <div className='item1'>
                            <h2 className='text-justify-start'>Sea Transport Services</h2>
                            <img src={icon1} className="icon-img" alt="icon" draggable='false' />
                        </div>
                        <p className="text-justify-end" >Following the quality of our service thus having gained trust of our many clients.</p>
                    </div>
                    <div className="service-item">
                        <div className='item1'>
                            <h2 className='text-justify-start'>Ware housing Services</h2>
                            <img src={icon2} className="icon-img" alt="icon" />
                        </div>
                        <p className="text-justify-end">Following the quality of our service thus having gained trust of our many clients.</p>
                    </div>
                    <div className="service-item">
                        <div className='item1'>
                            <h2 className='text-justify-start'>Air Freight Services</h2>
                            <img src={icon3} className="icon-img" alt="icon" />
                        </div>
                        <p className="text-justify-end">Following the quality of our service thus having gained trust of our many clients.</p>
                    </div>
                    <div className="service-item">
                        <div className='item1'>
                            <img src={icon4} className="icon-img" alt="icon" />
                            <h2 className="text-justify-end">Project and Exhibition</h2>
                        </div>
                        <p className="text-justify-start">Following the quality of our service thus having gained trust of our many clients.</p>
                    </div>
                    <div className="service-item">
                        <div className='item1'>
                            <img src={icon5} className="icon-img" alt="icon" />
                            <h2 className="text-justify-end">Local Shipping Services</h2>
                        </div>
                        <p className="text-justify-start">Following the quality of our service thus having gained trust of our many clients.</p>
                    </div>
                    <div className="service-item">
                        <div className='item1'>
                            <img src={icon6} className="icon-img" alt="icon" />
                            <h2 className="text-justify-end">Customer Clearance</h2>
                        </div>
                        <p className="text-justify-start">Following the quality of our service thus having gained trust of our many clients.</p>
                    </div>
                </div> */}
                <div className="service-list">
                    {ServiceIcon.map((service, index) => (
                        <div className="service-item" key={index}>
                            <div className='item1'>
                                {index % 3 !== 3 && <h2 className='text-justify-start'>{service.title}</h2>}
                                <img src={service.icon} className="icon-img" alt="icon" draggable='false' />
                                {index % 3 === 3 && <h2 className="text-justify-end">{service.title}</h2>}
                            </div>
                            <p className={service.textAlign}>{service.description}</p>
                        </div>
                    ))}
                </div>
                <div className="service-description">
                    <h3>Our Services</h3>
                    <p>From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time.</p>
                    <button>Get Started</button>
                </div>
            </div>
            <div className="signup">
                <div>
                    <h3>Sign up now to enjoy personalized shipping rates!</h3>
                    <p>Benefit from our services and solutions designed to meet all of your shipping needs.</p>
                </div>
                <div>
                    {
                        !isLogIn ? <button>Open an Account</button> : " "
                    }

                </div>
            </div>

            {/* boxes  */}

            <div className="service-feature">
                <div className='border1'></div>
                <div className='border2'></div>
                <div className="sizeBox">
                    <h2
                        style={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "35px",
                            marginBottom: "40px",
                        }}
                    >
                        Features and Benefits
                    </h2>
                    {/* Box Container */}
                    <div className="boxCont">
                        {ServiceData.map((e, i) => (
                            <div className="box" key={i}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className='img-box'>
                                        <img src={e.img} alt="" draggable="false" />

                                    </div>
                                    <h3>{e.heading}</h3>
                                </div>
                                <p>{e.para}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='service-work'>
                <h2>
                    How are Services works?
                </h2>
                <div className='work-detail'>
                    <div>
                        <img src={ServiceWork} alt="service-work" draggable='false' />
                    </div>
                    <div>
                        <p>Innovatively streamlining the shipping process, our service works by seamlessly integrating advanced logistics technology with personalized customer care, ensuring swift and secure transit from pickup to delivery while empowering businesses and individuals with simplified solutions tailored to their unique needs."</p>
                        <button>Get a quote</button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Service;
