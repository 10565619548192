import React, { useState } from "react";
// Contact CSS
import "./Contact.css";

// Image
import contactImg from "./Assets/contact.png";
import axios from 'axios';
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = ({ contactRef }) => {
  // Contact Form UseState
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [feedback, setFeedback] = useState(null);

  // Handle Contact Change Func
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  // Handle Contact Submit Func
  const handleContactSubmit = async(event) => {
    event.preventDefault();
    // setContactForm({
    //   name: "",
    //   email: "",
    //   message: "",
    // });
    if(contactForm.name ==='' || contactForm.email ===''){
      alert("kindly fill the details");
    }
    await axios.post(`${BACKEND_SERVER}/feedback/create-feedback`, contactForm).then((res)=>{
      setFeedback(res.data);
      toast.success('Your data saved successfully !! We will contct you soon.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      console.log(res.data);
      setContactForm({
      name: "",
      email: "",
      message: "",
    });
    }).catch((err)=>{
      console.log(err);
      toast.error('Something went wrong !! Try again after some time.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    })
  };

  return (
    <>
      {/* Main Contact Box */}
      <div className="contactBox" ref={contactRef}>
        {/* Sizebox for Large screen responsive */}
        <div className="sizeBox">
          <h2>Have any Questions?</h2>
          {/* Box Container */}
          <div className="boxCont">
            {/* Left Box */}
            <div className="box">
              <img src={contactImg} alt="" draggable="false" />
              <h3
                style={{
                  margin: "20px 0",
                  color: "#FFD078",
                }}
              >
                REQUEST A CALLBACK
              </h3>
              <h1
                style={{
                  color: "#D4D4D4",
                }}
              >
                We will contact in the shortest time.
              </h1>
            </div>

            {/* Right Box */}
            <div className="box">
              {/* Form */}
              <form onSubmit={handleContactSubmit}>
                {/* Name Field */}
                <input
                  type="text"
                  name="name"
                  required
                  value={contactForm.name}
                  placeholder="Name"
                  onChange={handleContactChange}
                />
                {/* Email Field */}
                <input
                  type="email"
                  name="email"
                  required
                  value={contactForm.email}
                  placeholder="Email"
                  onChange={handleContactChange}
                />
                {/* Textarea Field */}
                <textarea
                  name="message"
                  required
                  value={contactForm.message}
                  placeholder="Message"
                  onChange={handleContactChange}
                />
                {/* Submit Button */}
                <button type="submit">
                  Send Message{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-6"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
