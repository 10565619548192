import React from 'react';
import { useForm } from '../../../Contexts/FormContext/FormContext';
import ProgressBar from '../ProgressBar/ProgressBar';
import './PaymentStep.css';
import axios from 'axios';
import Cookies from "js-cookie";
import { BACKEND_SERVER } from '../../../Helper/BaseUrl';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successimg from "./Assets/successapna.png"
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { useLoaderContext } from '../../../Contexts/LoaderContext/LoaderContext';
import Loader from '../../Loader/Loader';

const MySwal = withReactContent(Swal);

function PaymentStep() {
  const { formData } = useForm();
  // Data from UseLoaderContext Hook
  const { openLoader, setOpenLoader } = useLoaderContext();
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      senderName: formData?.details?.senderName,
      senderPhone: formData?.details?.senderPhone,
      senderEmail: formData?.details?.senderEmail,
      senderCountry: formData?.address?.fromCountry,
      senderCity: formData?.address?.fromCity,
      senderPincode: formData?.address?.fromPincode,
      senderAddress: formData?.address?.fromAddress,
      pickupAddress: formData?.details?.pickupAddress,
      receiverName: formData?.details?.receiverName,
      receiverPhone: formData?.details?.receiverPhone,
      receiverEmail: formData?.details?.receiverEmail,
      receiverCountry: formData?.address?.toCountry,
      receiverCity: formData?.address?.toCity,
      receiverPincode: formData?.address?.toPincode,
      receiverAddress: formData?.address?.toAddress,
      dropoffAddress: formData?.details?.dropoffAddress,
      parcelSize: formData?.parcel?.parcelSize,
      parcelWeight: formData?.parcel?.parcelWeight,
      parcelContent: formData?.parcel?.parcelContent,
      parcelInstructions: formData?.parcel?.parcelInstructions,
      parcelQuantity: formData?.parcel?.parcelNumber,
      shippingAmount: formData?.amount?.shippingAmount,
      pickupAmount: formData?.amount?.pickupAmount,
      taxAmount: formData?.amount?.taxAmount,
      totalAmount: formData?.amount?.totalAmount
    }

    const token = Cookies.get("parcelToken");
    const id = Cookies.get("parcelUserId");

    setOpenLoader(true);

    axios.post(`${BACKEND_SERVER}/parcel/ship-parcel/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      setOpenLoader(true);
      MySwal.fire({
        html: '<h2>Great! Your parcel has been shipped successfully</h2>',
        imageUrl: `${successimg}`,
        imageHeight: 120,
        imageWidth: 120,
        showCloseButton: true,
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = `/track-parcel/${res?.data?.shipping_id}`;
      })
    }).catch
      ((err) => {
        console.log("Error in Submission", err);
      })
  };

  return (
    <div className="place-order-container">
      <ScrollToTop />
      <ProgressBar activeStep={5} />
      <div className="place-order-bottom-container">
        <div className="container">
          <div className="form-container">
            <form className="parcel-step-form" onSubmit={handleSubmit}>
              <div className="form-section">
                <h2 className="parcel-step-heading">Payment Information</h2>
                {/* Add your payment form fields here */}
              </div>
              <button className="parcel-step-type-submit" type="submit">Complete Payment</button>
            </form>
          </div>
        </div>
      </div>
      {/* Loader */}
      {openLoader && <Loader />}
    </div>
  );
}

export default PaymentStep;
