import React, { useState } from "react";
// SignIn CSS
import "./SignIn.css";

// Js Cookie
import Cookies from "js-cookie";

/* ------------- Components ------------- */
import GoogleLogin from "../GoogleLogin/GoogleLogin";

// UseLoaderContext Custom Hook
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";

/* ------------- Fetch ------------- */
// Axios
import axios from "axios";

/* ------------- Backend Url ------------- */
// Backend Server
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

/* ------------- React Router Dom ------------- */
import { Link } from "react-router-dom";

const Login = () => {
  // Data from UseLoaderContext Hook
  const { setOpenLoader } = useLoaderContext();

  // Email UseState
  const [email, setEmail] = useState("");
  // Password UseState
  const [password, setPassword] = useState("");

  // Email Alert UseState
  const [emailAlert, setEmailAlert] = useState("");
  // Password Alert UseState
  const [passAlert, setPassAlert] = useState("");

  // Show Password UseState
  const [showPassword, setShowPassword] = useState(false);

  // Box Style CSS
  const boxStyleForm = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderBottom: "2px solid #1f1f1f",
    margin: "8px 0",
  };

  // Alert Style CSS
  const alertStyle = {
    color: "#EA4335",
    fontSize: "14px",
    margin: "5px",
    fontWeight: "500",
  };

  const handleLogin = (event) => {
    // Stop Reloading the Page when Submitting the Form
    event.preventDefault();

    // Check if the form is filled or not
    if (email && emailAlert === "" && password && passAlert === "") {
      // Open Loader
      setOpenLoader(true);

      // Login API
      axios
        .post(`${BACKEND_SERVER}/user/login`, {
          email,
          password,
        })
        .then((res) => {
          // Storing data in Cookie
          Cookies.set("parcelToken", res.data.token);
          Cookies.set("parcelUserId", res.data.userid);
          Cookies.set("parcelType", res.data.type);

          const id = res.data.userid;
          const token = res.data.token;

          if (id && token) {
            axios
              .get(`${BACKEND_SERVER}/user/get-profile/${id}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                const userProfile = res.data;

                // Close Loader
                setOpenLoader(false);

                if (!userProfile.phoneNo) {
                  window.location.href = "/edit-profile";
                } else {
                  window.location.href = "/";
                }
              })
              .catch((err) => {
                console.error(err);
                setOpenLoader(false); // Close Loader
                // Optionally, you can set an error state here to show an error message to the user
              });
          }
        })
        .catch((err) => {
          console.error(err);
          // Close Loader
          setOpenLoader(false);
          // Optionally, you can set an error state here to show an error message to the user
        });
    } else {
      // Optionally, you can set an error state here to show an error message to the user
    }
  };

  return (
    <>
      {/* Main Div */}
      <div className="loginPage">
        <h2>Login</h2>
        <p>Welcome back! Please login to your account.</p>

        {/* Login Form */}
        <form onSubmit={handleLogin}>
          {/* Email Box */}
          <div style={boxStyleForm}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
              style={{
                width: "25px",
                height: "25px",
                marginRight: "5px",
                color: "#800020",
              }}
            >
              <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
              <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
            </svg>

            <input
              type="email"
              name="email"
              value={email}
              required
              id="email"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onBlur={() => {
                if (
                  !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                    email
                  )
                ) {
                  setEmailAlert("Invalid email address !!");
                } else {
                  setEmailAlert("");
                }
              }}
            />
          </div>
          {/* Email Alert */}
          <p style={alertStyle}>{emailAlert}</p>

          {/* Password Box */}
          <div style={boxStyleForm}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
              style={{
                width: "25px",
                height: "25px",
                marginRight: "5px",
                color: "#800020",
              }}
            >
              <path
                fillRule="evenodd"
                d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                clipRule="evenodd"
              />
            </svg>

            <input
              type={showPassword ? "text" : "password"}
              name="password"
              required
              value={password}
              id="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant="standard"
              onBlur={() => {
                if (
                  !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-=,./<>?;:'"[\]{}|~`])(?=.{8,})/.test(
                    password
                  )
                ) {
                  setPassAlert(
                    "Password must be 8+ characters with lowercase, uppercase, number, and special character."
                  );
                } else {
                  setPassAlert("");
                }
              }}
            />

            {/* Password Icon */}
            {showPassword ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
                onClick={() => setShowPassword(false)}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#800020",
                  cursor: "pointer",
                }}
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
                onClick={() => setShowPassword(true)}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#800020",
                  cursor: "pointer",
                }}
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </div>
          {/* Password Alert */}
          <p style={alertStyle}>{passAlert}</p>

          {/* Forgot Password */}
          <Link
            to="/forgot-password"
            style={{
              alignSelf: "flex-end",
              textDecoration: "none",
              fontSize: "15px",
              fontWeight: "500",
              color: "#9c9b9b",
              marginBottom: "15px",
            }}
          >
            Forgot password?
          </Link>

          {/* Login Button */}
          <button className="button" type="submit">
            Sign In
          </button>
        </form>

        {/* Google Login Component */}
        <GoogleLogin data={"Sign in with Google"} />

        <span
          style={{
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Don’t have an account?{" "}
          <Link
            to="/signup"
            style={{
              color: "#800020",
              fontWeight: "500",
              textDecoration: "none",
            }}
          >
            Sign up
          </Link>
        </span>
      </div>
    </>
  );
};

export default Login;
