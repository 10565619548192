import React from 'react';
import { useForm } from '../../../Contexts/FormContext/FormContext';
import ProgressBar from '../ProgressBar/ProgressBar';
import './ReviewStep.css';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';

function ReviewStep({ nextStep }) {
  const { formData } = useForm();

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep('payment');
  };

  const editaddress = (e) => {
    e.preventDefault();
    nextStep('address');
  };

  return (
    <div className="place-order-container">
      <ScrollToTop />
      <ProgressBar activeStep={4} />
      <div className="place-order-bottom-container">
        <div className='container'>
          <div className="form-container">
            <form className="parcel-step-form" onSubmit={handleSubmit}>
              <div className="form-section">
                <h2 className="parcel-step-heading">Review your information</h2>

                <div className='section'>
                  <div className='information-container'>
                    <h3 className='section-title'>Sender Information <a href="/address" onClick={editaddress}>Edit</a></h3>
                    <div><strong>Country:</strong> {formData.address.fromCountry}</div>
                    <div><strong>City/Town:</strong> {formData.address.fromCity}</div>
                    <div><strong>Pincode:</strong> {formData.address.fromPincode}</div>
                    <div><strong>Address:</strong> {formData.address.fromAddress}</div>
                  </div>
                </div>

                <div className='section'>
                  <div className='information-container'>
                    <h3 className='section-title'>Your Parcel</h3>
                    <div><strong>Size:</strong> {formData.parcel.parcelSize}</div>
                    <div><strong>Weight:</strong> {formData.parcel.parcelWeight}</div>
                    <div><strong>Content:</strong> {formData.parcel.parcelContent}</div>
                    <div><strong>Special handling instructions:</strong> {formData.parcel.parcelInstructions}</div>
                    <div><strong>Number of parcels:</strong> {formData.parcel.parcelNumber}</div>
                  </div>
                </div>

                <div className='section'>
                  <div className='information-container'>
                    <h3 className='section-title'>Receiver Information</h3>
                    <div><strong>Name:</strong> {formData.details.receiverName}</div>
                    <div><strong>Phone:</strong> {formData.details.receiverPhone}</div>
                    <div><strong>Email:</strong> {formData.details.receiverEmail}</div>
                    <div><strong>Address:</strong> {formData.details.dropoffAddress}</div>
                  </div>
                </div>

                <div className='section'>
                  <div className='information-container amount-information-container'>
                    <h3 className='section-title amount-title'>Calculated Amount</h3>
                    <div><strong>Shipping Amount:</strong> {formData?.amount?.shippingAmount}/-</div>
                    <div><strong>Pickup Amount:</strong> {formData?.amount?.pickupAmount}/-</div>
                    <div><strong>Tax:</strong> {formData?.amount?.taxAmount}/-</div>
                    <hr></hr>
                    <div className='total-amount-div'><strong>Total Amount:</strong> {formData?.amount?.totalAmount}/-</div>
                  </div>
                </div>
              </div>
              <div className='navigate-container'>
                <div className='navigation-container'>
                  <button className="review-step-type-button" type="button" onClick={() => nextStep('details')}>Back</button>
                  <button className="review-step-type-submit" type="submit">Proceed</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewStep;
