import React from "react";
import "./Track.css";

//Track Cards Content
import track_data from "./assets/assets";

const Track = () => {
  return (
    <>
      {/* main div */}
      <div className="track-main">
        <h1>How To Track Orders</h1>
        <div>
          {/* Track Cards */}
          <div className="track-container">
            {/* mapping card data from track_data */}
            {track_data.map((services, index) => {
              return (
                <div key={index} className="track-card">
                  <img key={index} src={services.img} alt="" />
                  <h3>{services.h3}</h3>
                  <p>{services.para}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Track;
