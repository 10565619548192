import React from "react";
import "./AboutUs.css";
import Photo1 from "./frontPhoto.png";
import Photo2 from "./frontPhoto2.png";
import Photo3 from "./Recatangle.png";
import Photo4 from "./small-box.png";
import {
  eighthHomeContent,
  fifthHomeContent,
  seventhHomeContent,
  sixthHomeContent,
  thirdHomeContent,
} from "../Home/HomeContent.js";
import logo1 from "./logo1.png";
import logo2 from "./logo2.png";
import logo3 from "./logo3.png";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";

const AboutUs = () => {
  return (
    <div className="parent">
      <ScrollToTop />
      <div className="upper">
        <div className="upper-heading">
          <h1>About Us</h1>
          <p>
            From local deliveries to international shipments, we offer
            efficient, reliable, and cost-effective options to ensure your
            packages reach their destination safely and on time.
          </p>
        </div>
        <div className="images">
          <img className="photo1" src={Photo1} alt="postman"></img>
          <img className="photo2" src={Photo2} alt="postman"></img>
        </div>
      </div>
      <div className="second-upper">
        <div className="left">
          <img className="lg-img" src={Photo3} alt="post-man"></img>
          <img className="sm-img" src={Photo4} alt="post-man"></img>
        </div>
        <div className="right">
          <h1>Our Company Overview</h1>
          <p>
            Our mission is simple: to provide reliable, efficient, and
            cost-effective courier services that exceed our customers'
            expectations every time. We're committed to delivering peace of mind
            along with every parcel, ensuring that your shipments reach their
            destination safely and on time.
          </p>
        </div>
      </div>
      <div className="middle-bg">
        <div className="middle">
          <h1>Our Logistic Services</h1>
          <div className="boxes">
            <div className="box">
              <img src={logo1} alt="logo"></img>
              <h5>Sea Transport Services</h5>
              <p>
                Following the quality of our service thus having gained trust of
                our many clients.
              </p>
            </div>
            <div className="box">
              <img src={logo2} alt="logo"></img>
              <h5>Warehousing Services</h5>
              <p>
                Following the quality of our service thus having gained trust of
                our many clients.
              </p>
            </div>
            <div className="box">
              <img src={logo3} alt="logo"></img>
              <h5>Air Fright Services</h5>
              <p>
                Following the quality of our service thus having gained trust of
                our many clients.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="plain-box">
        <div className="text-area">
          <h1>
            Sign up now to enjoy personalized<br></br>
            shipping rates!
          </h1>
          <p>
            Benefit from our services and solutions designed to meet all of your
            shipping needs.
          </p>
        </div>
        <button>Open an Account</button>
      </div>
      <div className="stats">
        <div className="heading">
          <h1>Stats For Courier Delievry</h1>
          <p>
            "Experience our courier service's impressive statistics, showcasing
            our efficiency, reliability, and commitment to timely deliveries,
            backed by data-driven excellence."
          </p>
        </div>
        <div className="boxes-sec">
          <div className="box-sec">
            <p className="number">
              95%<span>+</span>
            </p>
            <p className="para">morethan success deliveries</p>
          </div>
          <div className="box-sec">
            <p className="number">
              2600<span>+</span>
            </p>
            <p className="para">morethan success deliveries</p>
          </div>
          <div className="box-sec">
            <p className="number">
              5-<span>Star</span>
            </p>
            <p className="para">morethan success deliveries</p>
          </div>
          <div className="box-sec">
            <p className="number">
              2M<span>+</span>
            </p>
            <p className="para">morethan success deliveries</p>
          </div>
        </div>
      </div>
      <div className="eighthHomeCont">
        {/* Sizebox for Large screen responsive */}
        <div className="sizeBox">
          <h2
            style={{
              marginTop: "40px",
            }}
          >
            Trusted by Top Companies
          </h2>
          <div
            style={{
              // width: "70%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              margin: "40px 100px",
            }}
          >
            {eighthHomeContent.map((t, i) => (
              <img src={t.img} alt="" key={i} draggable="false" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
