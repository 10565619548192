import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";

/* --------- Components --------- */
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import SignUp from "../Components/SignUp/SignUp";
import SignIn from "../Components/SignIn/SignIn";
import ProfilePage from "../Components/Profile/ProfilePage";
import EditProfile from "../Components/EditProfile/EditProfile";
import OrderParcel from "../Components/Shipment/OrderParcel/OrderParcel";

/* --------- Pages --------- */
import Home from "../Pages/Home/Home";
import Register from "../Pages/Register/Register";
import Contact from "../Pages/Contact/Contact";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Service from "../Pages/Services/Service";
import Faq from "../Pages/FAQ/Faq";
import ShipmentDetail from "../Components/ShippmentDetail/ShippmentDetail";
import ParcelDetail from "../Components/ShippmentDetail/ParcelDetail";

const AppRouter = () => {
  const PrivateRoute = ({ element }) => {
    const authed = Cookies.get("parcelToken");
    return authed ? element : <Navigate to="/" replace />;
  };

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<Home />} />
          {/* Contact Route */}
          <Route path="/contact" element={<Contact />} />
          {/* AboutUs Route */}
          <Route path="/about" element={<AboutUs />} />
          {/* Services Route */}
          <Route path="/services" element={<Service />} />
          {/* FAQ Route */}
          <Route path="/faq" element={<Faq />} />

          {/* SignIn Route */}
          <Route path="/signin" element={<Register data={<SignIn />} />} />
          {/* SignUp Route */}
          <Route path="/signup" element={<Register data={<SignUp />} />} />

          {/* Profile Route */}
          <Route
            path="/profile"
            element={<PrivateRoute element={<ProfilePage />} />}
          />
          {/* Edit Profile Route */}
          <Route
            path="/edit-profile"
            element={<PrivateRoute element={<EditProfile />} />}
          />

          {/* OrderParcel Route */}
          <Route
            path="/order-parcel"
            element={<PrivateRoute element={<OrderParcel />} />}
          />
          {/* Shipping History Route */}
          <Route
            path="/track-parcel/:sid"
            element={<PrivateRoute element={<ParcelDetail />} />}
          />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default AppRouter;
