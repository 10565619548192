import Delivery from './servicesimg/Delivery.png'
import Calender from './servicesimg/Calendar.png'
import Service from './servicesimg/Service.png'
import check from './servicesimg/check.png'

//icons data 
import icon1 from './servicesimg/icon1.png';
import icon2 from './servicesimg/icon2.png';
import icon3 from './servicesimg/icon3.png';
import icon4 from './servicesimg/icon4.png';
import icon5 from './servicesimg/icon5.png';
import icon6 from './servicesimg/icon6.png';



export const ServiceData = [
    {
        img: Delivery,
        heading: "Fast Delivery times",
        para: "You can trust us to safely deliver your most sensitive documents to the specific area in a short time.",
    },
    {
        img: Calender,
        heading: "Tracking options",
        para: "Keep tabs on your shipments every step of the way with real-time tracking, providing peace of mind and transparency.",
    },
    {
        img: Service,
        heading: "Packaging Solutions",
        para: "Take advantage of our expert packaging services to ensure your items are safely  packed for transit, reducing the risk of damage.",
    },
    {
        img: check,
        heading: "shipping options",
        para: "Tailor your shipping experience to suit your specific needs with customizable options such as delivery preferences, additional services.",
    }
]

export const ServiceIcon = [
    {
        title: 'Sea Transport Services',
        icon: icon1,
        description: 'Following the quality of our service thus having gained trust of our many clients.',
        textAlign: 'text-justify-end'
    },
    {
        title: 'Warehousing Services',
        icon: icon2,
        description: 'Following the quality of our service thus having gained trust of our many clients.',
        textAlign: 'text-justify-end'
    },
    {
        title: 'Air Freight Services',
        icon: icon3,
        description: 'Following the quality of our service thus having gained trust of our many clients.',
        textAlign: 'text-justify-end'
    },
    {
        title: 'Project and Exhibition',
        icon: icon4,
        description: 'Following the quality of our service thus having gained trust of our many clients.',
        textAlign: 'text-justify-start'
    },
    {
        title: 'Local Shipping Services',
        icon: icon5,
        description: 'Following the quality of our service thus having gained trust of our many clients.',
        textAlign: 'text-justify-start'
    },
    {
        title: 'Customer Clearance',
        icon: icon6,
        description: 'Following the quality of our service thus having gained trust of our many clients.',
        textAlign: 'text-justify-start'
    }
];
