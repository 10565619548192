import React, { useEffect, useState, useRef } from "react";

// UseRegisterContext Custom Hook
import { useRegisterContext } from "../../Contexts/RegisterContext/RegisterContext";
// UseLoaderContext Custom Hook
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";

/* ------------- Fetch ------------- */
// Axios
import axios from "axios";

/* ------------- Backend Url ------------- */
// Backend Server
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

const OTP = () => {
  // Data from UseRegisterContext Hook
  const { regData, setRegData } = useRegisterContext();

  // Data from UseLoaderContext Hook
  const { setOpenLoader } = useLoaderContext();

  // OTP UseState
  const [otp, setOtp] = useState(new Array(4).fill(""));
  // OTP UseRef
  const otpInputRefs = useRef([]);

  // Timer UseState
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    // Set focus on the first OTP input field when the component mounts
    if (otpInputRefs.current[0]) {
      otpInputRefs.current[0].focus();
    }

    // 1 minute timer
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Formating time
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}s`;
  };

  // Handle OTP Change Func
  const handleOtpChange = (e, i) => {
    const inputValue = e.target.value;
    // Check if input value is not a number
    if (isNaN(inputValue)) {
      // If not a number, prevent further action
      return;
    }

    // If it is a number, update the OTP state
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[i] = inputValue; // Update the value at index i
      return updatedOtp;
    });

    // Move focus to the next input field if it exists
    const nextInput = e.target.nextSibling;
    if (inputValue && nextInput) {
      nextInput.focus();
    }
  };

  // UseEffect for verifying OTP
  useEffect(() => {
    // When 4digit OTP Properly fill
    if (otp.every((value) => value !== "") && regData) {
      // Open Loader
      setOpenLoader(true);

      const data = {
        _id: regData._id,
        email: regData.email,
        otp: otp.join(""),
      };

      // Verify OTP API
      axios
        .post(`${BACKEND_SERVER}/user/verify-otp`, data)
        .then((res) => {
          // console.log(res.data);

          // Close Loader
          setOpenLoader(false);

          // Set User Data for Verification
          setRegData(res.data);
        })
        .catch((err) => {
          // console.log(err);

          // Close Loader
          setOpenLoader(false);
        });
    }
  }, [otp]);

  // Handle Resend OTP Func
  const handleResendOTP = (event) => {
    // Stop Reloading the Page when Submiting the Form
    event.preventDefault();

    // Check if the form is fill or not
    if (regData && regData.email) {
      // Open Loader
      setOpenLoader(true);

      // Resend Verification OTP API
      axios
        .post(`${BACKEND_SERVER}/users/resend-verification`, {
          email: regData.email,
        })
        .then((res) => {
          //   console.log(res.data);

          // Close Loader
          setOpenLoader(false);

          // Set User Data for Verification
          setRegData(res.data);

          setOtp(new Array(4).fill(""));
          if (otpInputRefs.current[0]) {
            otpInputRefs.current[0].focus();
          }
        })
        .catch((err) => {
          //   console.log(err);

          // Close Loader
          setOpenLoader(false);
        });
    }
  };

  return (
    <>
      <h2>OTP Verification</h2>
      <p>
        Please enter the OTP sent to your registered email to complete your
        verification.
      </p>

      {/* Form OTP */}
      <form onSubmit={handleResendOTP}>
        {/* OTP div */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="otpBox"
        >
          {otp.map((data, i) => {
            return (
              <input
                ref={(inputRef) => {
                  otpInputRefs.current[i] = inputRef;
                }}
                type="text"
                key={i}
                maxLength={1}
                value={data}
                onChange={(e) => handleOtpChange(e, i)}
              />
            );
          })}
        </div>

        {/* Resend OTP */}
        <span
          style={{
            fontSize: "15px",
            color: "#1f1f1f",
            marginTop: "15px",
          }}
        >
          Resend OTP in :
          {timer !== 0 ? (
            <span
              style={{
                fontWeight: "bold",
                color: "#800020",
                padding: "5px",
                display: "inline-block",
                width: "65px",
              }}
            >
              {formatTime(timer)}
            </span>
          ) : (
            <span
              style={{
                fontWeight: "bold",
                color: "#800020",
                cursor: "pointer",
                width: "65px",
                paddingLeft: "5px",
              }}
              onClick={handleResendOTP}
            >
              RESEND
            </span>
          )}
        </span>
      </form>
    </>
  );
};

export default OTP;
