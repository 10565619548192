// src/components/DetailsStep/DetailsStep.js
import React, { useState, useEffect } from 'react';
import { useForm } from '../../../Contexts/FormContext/FormContext';
import './DetailsStep.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BACKEND_SERVER } from '../../../Helper/BaseUrl';
import ProgressBar from '../ProgressBar/ProgressBar';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';

function DetailsStep({ nextStep }) {
  const { formData, updateFormData } = useForm();

  const [userProfileData, setUserProfileData] = useState(null);

  useEffect(() => {
    const token = Cookies.get('parcelToken')
    const id = Cookies.get('parcelUserId')

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BACKEND_SERVER}/user/get-profile/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUserProfileData(response.data);
      } catch (error) {
        console.log("Error getting user profile", error);
      }
    }
    fetchUserData();
  }, []);


  const [detailsData, setDetailsData] = useState({
    senderName: '',
    senderPhone: '',
    senderEmail: '',
    pickupAddress: '',
    receiverName: '',
    receiverPhone: '',
    receiverEmail: '',
    dropoffAddress: '',
  });

  useEffect(() => {
    if (formData.details && userProfileData) {
      setDetailsData({
        senderName: formData.details.senderName || userProfileData?.name,
        senderPhone: formData.details.senderPhone || userProfileData?.phoneNo,
        senderEmail: formData.details.senderEmail || userProfileData?.email,
        pickupAddress: formData.details.pickupAddress || formData.address.fromAddress,
        receiverName: formData.details.receiverName || '',
        receiverPhone: formData.details.receiverPhone || '',
        receiverEmail: formData.details.receiverEmail || '',
        dropoffAddress: formData.details.dropoffAddress || formData.address.toAddress,
      });
    }
  }, [formData.address.fromAddress, formData.address.toAddress, formData.details, userProfileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetailsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData('details', detailsData);
    nextStep('review');
  };

  return (
    <div className="place-order-container">
      <ScrollToTop />
      <ProgressBar activeStep={3} />
      <div className="place-order-bottom-container">
        <div className="container">
          <div className="form-container">
            <form className="parcel-step-form" onSubmit={handleSubmit}>
              <div className="form-section">
                <h2 className="parcel-step-heading">Sender Information</h2>
                <label className="parcel-step-label" htmlFor="sender-name">Name</label>
                <input
                  className="parcel-step-input"
                  required
                  type="text"
                  id="sender-name"
                  name="senderName"
                  onChange={handleChange}
                  value={detailsData.senderName}
                />
                <div className="sender-contact-container">
                  <div className="contact-container">
                    <label className="parcel-step-label" htmlFor="sender-phone">Phone of the sender</label>
                    <input
                      className="parcel-step-input"
                      required
                      type="number"
                      id="sender-phone"
                      name="senderPhone"
                      onChange={handleChange}
                      value={detailsData.senderPhone}
                    />
                  </div>
                  <div className="contact-container">
                    <label className="parcel-step-label" htmlFor="sender-email">Email of the sender</label>
                    <input
                      className="parcel-step-input"
                      required
                      type="email"
                      id="sender-email"
                      name="senderEmail"
                      onChange={handleChange}
                      value={detailsData.senderEmail}
                    />
                  </div>
                </div>
                <label className="parcel-step-label" htmlFor="pickup-address">Pickup Address</label>
                <input
                  className="parcel-step-input"
                  required
                  type="text"
                  id="pickup-address"
                  name="pickupAddress"
                  onChange={handleChange}
                  value={detailsData.pickupAddress}
                />
                <div className="notice-container">
                  <div className="notice">
                    <p className="note-para">
                      <span className="note">*Note - </span> Extra charges will be taken to pick up the order, otherwise you can also transport it to our nearest office.
                    </p>
                  </div>
                  <span className="know-more">
                    <a href="/">Click here to know more about this.</a>
                  </span>
                </div>
                <h2>Receiver Information</h2>
                <label className="parcel-step-label" htmlFor="receiver-name">Name</label>
                <input
                  className="parcel-step-input"
                  required
                  type="text"
                  id="receiver-name"
                  name="receiverName"
                  onChange={handleChange}
                  value={detailsData.receiverName}
                />
                <div className="receiver-contact-container">
                  <div className="contact-container">
                    <label className="parcel-step-label" htmlFor="receiver-phone">Phone of the receiver</label>
                    <input
                      className="parcel-step-input"
                      required
                      type="number"
                      id="receiver-phone"
                      name="receiverPhone"
                      onChange={handleChange}
                      value={detailsData.receiverPhone}
                    />
                  </div>
                  <div className="contact-container">
                    <label className="parcel-step-label" htmlFor="receiver-email">Email of the receiver</label>
                    <input
                      className="parcel-step-input"
                      required
                      type="email"
                      id="receiver-email"
                      name="receiverEmail"
                      onChange={handleChange}
                      value={detailsData.receiverEmail}
                    />
                  </div>
                </div>
                <label className="parcel-step-label" htmlFor="dropoff-address">Dropoff Address</label>
                <input
                  className="parcel-step-input"
                  required
                  type="text"
                  id="dropoff-address"
                  name="dropoffAddress"
                  onChange={handleChange}
                  value={detailsData.dropoffAddress}
                />
              </div>
              <div className='navigate-container'>
                <div className='navigation-container'>
                  <button className="parcel-step-type-button" type="button" onClick={() => nextStep('parcel')}>Back</button>
                  <button className="parcel-step-type-submit" type="submit">Save & Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsStep;
