import React, { useRef } from "react";
// TestimonialsSlider.css
import "./TestimonialsSlider.css";

// Client Images
import client1 from "./Assets/client1.png";
import client2 from "./Assets/client2.png";

const TestimonialsSlider = () => {
  // Slider Reference
  const sliderRef = useRef(null);

  // User Data
  const userData = [
    {
      img: client1,
      para: "Step into a world where shipping is not just a transaction, but an opportunity to create lasting connections, build trust, and exceed expectations at every turn.",
    },
    {
      img: client2,
      para: "Step into a world where shipping is not just a transaction, but an opportunity to create lasting connections, build trust, and exceed expectations at every turn.",
    },
    {
      img: client1,
      para: "Step into a world where shipping is not just a transaction, but an opportunity to create lasting connections, build trust, and exceed expectations at every turn.",
    },
  ];

  // Scroll Left Func
  const scrollLeft = () => {
    sliderRef.current.scrollBy({
      left: -330,
      behavior: "smooth",
    });
  };

  // Scroll Right Func
  const scrollRight = () => {
    sliderRef.current.scrollBy({
      left: 330,
      behavior: "smooth",
    });
  };

  return (
    // Main Box
    <div className="testimonialBox">
      {/* Sizebox for Large screen responsive */}
      <div className="sizeBox">
        <h2>Testimonials</h2>
        {/* Main Slider */}
        <div className="mainSlider">
          {/* Left Arrow */}
          <button className="arrow left-arrow" onClick={scrollLeft}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          {/* Slider */}
          <div className="slider" ref={sliderRef}>
            {userData.map((u, i) => (
              <div className="box" key={i}>
                <img src={u.img} alt="" draggable="false" />
                <p>{u.para}</p>
              </div>
            ))}
          </div>

          {/* Right Arrow */}
          <button className="arrow right-arrow" onClick={scrollRight}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
