import React from 'react';
import './ProgressBar.css';

function ProgressBar({ activeStep }) {
  return (

    <div className="place-order-top-container">
        <h1 className="progress-bar-heading">Place your order</h1>
        <div className="progress-bar-container">
          <div className="steps">
            <div className={`step ${activeStep === 1 ? 'active' : ''}`}>
              <div className="circle">1</div>
              <div className="progressbar-label">Address</div>
            </div>
            <div className={`step ${activeStep === 2 ? 'active' : ''}`}>
              <div className="circle">2</div>
              <div className="progressbar-label">Parcel</div>
            </div>
            <div className={`step ${activeStep === 3 ? 'active' : ''}`}>
              <div className="circle">3</div>
              <div className="progressbar-label">Details</div>
            </div>
            <div className={`step ${activeStep === 4 ? 'active' : ''}`}>
              <div className="circle">4</div>
              <div className="progressbar-label">Review</div>
            </div>
            <div className={`step ${activeStep === 5 ? 'active' : ''}`}>
              <div className="circle">5</div>
              <div className="progressbar-label">Payment</div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ProgressBar;