import React, { useEffect, useState } from "react";
import profilepic from "./defaultprofile.png";
import axios from "axios";
import { BACKEND_SERVER } from "../../Helper/BaseUrl";
import Cookies from "js-cookie";
import { CiEdit } from "react-icons/ci";

import "./ProfilePage.css";
import { useNavigate } from "react-router-dom";
import NewShippingHistory from "./NewShippingHistory";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";
import Loader from "../Loader/Loader";

const ProfilePage = () => {
  const [userProfileData, setUserProfileData] = useState(null);
  const navigate = useNavigate();

  // Data from UseLoaderContext Hook
  const { openLoader, setOpenLoader } = useLoaderContext();

  useEffect(() => {
    const token = Cookies.get("parcelToken");
    const id = Cookies.get("parcelUserId");
    setOpenLoader(true);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_SERVER}/user/get-profile/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOpenLoader(false);
        setUserProfileData(response?.data);
      } catch (error) {
        console.log("Error getting user profile", error);
      }
    };
    fetchUserData();
  }, []);

  const FormatName = (name) => {
    if (!name) return "";
    return name.replace(/([A-Z])/g, " $1").trim();
  };

  return (
    <div className="profilepage-main">
      <ScrollToTop />
      <div className="Profilepage-container">
        <header>
          <div className="profile-heading">
            <h1>My Personal details</h1>
          </div>
          <div>
            {/* <IoSearch className="icon" />
                        <VscBell className="icon" />
                        <RiMessage2Line className="icon" /> */}
          </div>
        </header>
        <div className="personal-details">
          <div className="user-info">
            <CiEdit
              className="edit-profile"
              onClick={() => navigate("/edit-profile")}
            />
            <div className="user-details1">
              <div className="profilepic-name">
                <img
                  src={userProfileData?.profilePic || profilepic}
                  alt="User"
                  className="profile-pic"
                  draggable="false"
                />
                <h2 className="name-tag2">
                  Hey, {FormatName(userProfileData?.name)}
                </h2>
              </div>
              <div>
                <h2 className="name-tag">
                  Hey, {FormatName(userProfileData?.name)}
                </h2>
                <p>Full name - {FormatName(userProfileData?.name)}</p>
                <p>Contact no - {userProfileData?.phoneNo}</p>
                <p>Email address - {userProfileData?.email}</p>
              </div>
            </div>
            <div className="user-details2">
              <p>State - {userProfileData?.state}</p>
              <p>City - {userProfileData?.city} </p>
              <p>Country - {userProfileData?.country}</p>
              <p>Postal code - {userProfileData?.postalCode}</p>
              <div className="address-container">
                <p>Country code - {userProfileData?.countryCode}</p>
                <p>Address - {userProfileData?.address[0]}</p>
              </div>
            </div>
          </div>
          <div className="feedback">
            <h2>Your Feedbacks</h2>
          </div>
        </div>
        <div className="shipping-history">
          {/* <h2>Shipping History</h2> */}
          {/* <div className="shipping-item"></div>
                    <div className="shipping-item"></div> */}
          {/* <ShippingHistory /> */}
          <NewShippingHistory />
        </div>
      </div>
      {/* Loader */}
      {openLoader && <Loader />}
    </div>
  );
};

export default ProfilePage;
