import React, { useEffect, useState } from "react";
// Navbar CSS
import "./Navbar.css";

// Logo
import logo from "./Assets/Logo.jpg";
import track from "./Assets/track.svg";
import dashboard from "./Assets/dashboard.svg";
import history from "./Assets/history.svg";

/* ------------ React Router Dom ------------ */
import { Link, NavLink } from "react-router-dom";

// useLocationContext Custom Hook
import { useLocationContext } from "../../Contexts/LocationContext/LocationContext";

// Cookies
import Cookies from "js-cookie";

// Location Component
import LocationPopUp from "../LocationPopUp/LocationPopUp";
import axios from "axios";
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

const Navbar = () => {
  // Drawer Open UseState
  const [open, setOpen] = useState(false);

  // Location Context
  const {
    openLocation,
    setOpenLocation,
    setIsLocationFetched,
    isLocationFetched,
  } = useLocationContext();

  // NavList Details
  const navDetails = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
      link: "/about",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "FAQ",
      link: "/faq",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  // Getting location data
  useEffect(() => {
    const city = Cookies.get("city");
    const state = Cookies.get("state");
    const country = Cookies.get("country");
    const postcode = Cookies.get("postcode");

    // If data not present
    if (!city && !state && !country && !postcode) {
      // Pop up open
      setOpenLocation(true);
      // Location not fetched
      setIsLocationFetched(false);
    } else {
      // Location fetched
      setIsLocationFetched(true);
    }
  }, []);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const id = Cookies.get("parcelUserId");
    const token = Cookies.get("parcelToken");

    if (id && token) {
      axios
        .get(`${BACKEND_SERVER}/user/get-profile/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const [dropOpen, setDropOpen] = useState(false);

  return (
    <>
      {/* Main Navbar */}
      <div className="navbar">
        {/* Upper Navbar */}
        <div className="upNav">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            {/* Left Navbar */}
            <div className="leftNav">
              {/* Search Box */}
              <div className="searchBox">
                <input type="text" placeholder="Search" />
                {/* Search Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </div>
            </div>

            {/* Right Navbar */}
            <div className="rightNav">
              {/* Location Icon */}
              <span
                onClick={() => {
                  // Pop up open
                  setOpenLocation(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
                {isLocationFetched ? "Change Location" : "Find Location"}
              </span>
              {/* Location Pop Up Component */}
              {openLocation && <LocationPopUp />}
              <span>Country/Language</span>
            </div>
          </div>
        </div>

        {/* Down Navbar */}
        <div className="downNav">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            {/* Left Navbar */}
            <div className="leftNav">
              {/* Logo */}
              <Link to="/">
                <img src={logo} alt="" draggable="false" />
              </Link>
            </div>

            {/* Responsive Menu Icon */}
            <div
              className="menu"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            {/* Drawer Part */}
            <div className={`drawer ${open ? "open" : ""}`}>
              {/* Close Menu Icon */}
              <div
                className="menu"
                onClick={() => {
                  setOpen(!open);
                }}
                style={{
                  alignSelf: "flex-end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                  style={{
                    width: "35px",
                    height: "35px",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              {/* Middle Navbar */}
              <div className="middleNav">
                {navDetails.map((n, i) => (
                  <NavLink to={n.link} key={i}>
                    {n.name}
                  </NavLink>
                ))}
              </div>

              {/* Right Navbar */}
              <div className="rightNav">
                {!Cookies.get("parcelToken") && !Cookies.get("parcelUserId") ? (
                  <NavLink to="/signin">
                    {/* Person Icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    SignIn
                  </NavLink>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "550",
                      cursor: "pointer",
                      margin: "0 20px",
                      padding: "0.5em 0.5em",
                      position: "relative",
                    }}
                    onMouseEnter={() => {
                      setDropOpen(true);
                    }}
                    onMouseLeave={() => {
                      setDropOpen(false);
                    }}
                  >
                    {userData?.profilePic ? (
                      <img
                        src={userData?.profilePic}
                        alt=""
                        draggable="false"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginRight: "10px",
                          boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                        }}
                      />
                    ) : (
                      <>
                        {/* Person Icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-6"
                          style={{
                            width: "25px",
                            height: "25px",
                            color: "#800020",
                            marginRight: "5px",
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </>
                    )}
                    Hey, {userData?.name.split(" ")[0]}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#800020",
                        marginLeft: "10px",
                        transform: dropOpen ? "rotate(180deg)" : "",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {dropOpen && (
                      <div className="dropBox">
                        <Link to="/order-parcel">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <path d="M2.25 2.25a.75.75 0 0 0 0 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 0 0-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 0 0 0-1.5H5.378A2.25 2.25 0 0 1 7.5 15h11.218a.75.75 0 0 0 .674-.421 60.358 60.358 0 0 0 2.96-7.228.75.75 0 0 0-.525-.965A60.864 60.864 0 0 0 5.68 4.509l-.232-.867A1.875 1.875 0 0 0 3.636 2.25H2.25ZM3.75 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
                          </svg>
                          Order Parcel
                        </Link>

                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <path
                              fillRule="evenodd"
                              d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Track my shipping
                        </Link>

                        <Link to="/profile">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Profile
                        </Link>

                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <path d="M5.625 3.75a2.625 2.625 0 1 0 0 5.25h12.75a2.625 2.625 0 0 0 0-5.25H5.625ZM3.75 11.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75ZM3 15.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75ZM3.75 18.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z" />
                          </svg>
                          Shipping history
                        </Link>

                        <span
                          onClick={() => {
                            Cookies.remove("parcelUserId");
                            Cookies.remove("parcelToken");
                            window.location.href = "/";
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Logout
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
