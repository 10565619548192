import React, { useState, useEffect, useRef } from "react";
// Home CSS
import "./Home.css";

// Js Cookie
import Cookies from "js-cookie";

/* ------------ React Router Dom ------------ */
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Contact Component
import Contact from "../../Components/Contact/Contact";
// TestimonialsSlider Component
import TestimonialsSlider from "../../Components/TestimonialsSlider/TestimonialsSlider";
// TrackParcel Component
import TrackPlaceBox from "../../Components/TrackPlaceBox/TrackPlaceBox";

// All Data Content
import {
  eighthHomeContent,
  fifthHomeContent,
  seventhHomeContent,
  sixthHomeContent,
  thirdHomeContent,
} from "./HomeContent";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop";

const Home = () => {
  // Contact Ref
  const contactRef = useRef(null);

  // ScrollToContact Function
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const userId = params.get("userid");
  const type = params.get("type");

  // State to track whether cookies have been set
  const [cookiesSet, setCookiesSet] = useState(false);

  useEffect(() => {
    // Check if all parameters are available
    if (token && userId && type) {
      // Set cookies with encrypted values
      Cookies.set(encodeURIComponent("parcelToken"), encodeURIComponent(token));
      Cookies.set(
        encodeURIComponent("parcelUserId"),
        encodeURIComponent(userId)
      );
      Cookies.set(encodeURIComponent("parcelType"), encodeURIComponent(type));

      // Update state to indicate that cookies have been set
      setCookiesSet(true);
    }
  }, [token, userId, type]);

  // Redirect to / route only after cookies have been set
  useEffect(() => {
    if (cookiesSet) {
      window.location.href = "/";
    }
  }, [cookiesSet]);

  return (
    <>
    <ScrollToTop/>
      {/* Main Home Container */}
      <div className="homeContainer">
        {/* First Container */}
        <div className="firstHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            {/* Tracking Box */}
            <TrackPlaceBox />
          </div>
        </div>

        {/* Second Container */}
        <div className="secondHomeCont">
          <p>Easiest and fastest courier service awaits you</p>
          <button onClick={scrollToContact}>Contact Us</button>
        </div>

        {/* Third Container */}
        <div className="thirdHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            <h2
              style={{
                marginTop: "30px",
              }}
            >
              Manage Your Shipping
            </h2>
            {/* Box Container */}
            <div className="boxCont">
              {thirdHomeContent.map((e, i) => (
                <div
                  className="box"
                  key={i}
                  style={{
                    backgroundColor: i === 1 && "#800020",
                    color: i === 1 && "#fff",
                  }}
                >
                  <img src={e.img} alt="" draggable="false" />
                  <p>{e.para}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Fourth Container */}
        <div className="fourthHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div
            className="sizeBox"
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {/* Left Box */}
            <div className="left">
              <h2
                style={{
                  margin: "18px 5px",
                }}
              >
                Sign up now to enjoy personalized shipping rates!
              </h2>
              <p
                style={{
                  margin: "18px 5px",
                  fontWeight: "400",
                  fontSize: "0.9rem",
                }}
              >
                Benefit from our services and solutions designed to meet all of
                your shipping needs.
              </p>
            </div>
            {/* Button */}
            <Link to="/signup">
              <button>Open an Account</button>
            </Link>
          </div>
        </div>

        {/* Fifth Container */}
        <div className="fifthHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            <h2
              style={{
                marginTop: "50px",
              }}
            >
              Our services for you
            </h2>
            {/* Box Container */}
            <div className="boxCont">
              {fifthHomeContent.map((e, i) => (
                <div className="box" key={i}>
                  <img src={e.img} alt="" draggable="false" />
                  <p>{e.para}</p>
                  <span>{e.details}</span>
                  <ul>
                    {e.item.map((ei, index) => (
                      <li key={index}>{ei}</li>
                    ))}
                  </ul>
                  <button>{e.btn}</button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Sixth Container */}
        <div className="sixthHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            {sixthHomeContent.map((e, i) => (
              <div className="box" key={i}>
                <span>{e.data}</span>
                <p>{e.para}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Seventh Container */}
        <div className="seventhHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            <h2
              style={{
                color: "#fff",
              }}
            >
              Why Choose Us?
            </h2>
            {/* Box Container */}
            <div className="boxCont">
              {seventhHomeContent.map((e, i) => (
                <div className="box" key={i}>
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={e.img} alt="" draggable="false" />
                    {e.heading}
                  </h3>
                  <p>{e.para}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Eighth Container */}
        <div className="eighthHomeCont">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            <h2
              style={{
                marginTop: "40px",
              }}
            >
              Trusted by Top Companies
            </h2>
            <div
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "40px 0",
              }}
            >
              {eighthHomeContent.map((t, i) => (
                <img src={t.img} alt="" key={i} draggable="false" />
              ))}
            </div>
          </div>
        </div>

        {/* Contact Component */}
        <Contact contactRef={contactRef} />

        {/* Testimonials Slider */}
        <TestimonialsSlider />
      </div>
    </>
  );
};

export default Home;
