import React, { useState, useEffect } from "react";
import "./ShippmentDetail.css";
import { format, addDays } from "date-fns";
import ReviewEmoji from "./ReviewEmoji";


// Function to format date as "2nd May 2024"

const ShipmentDetail = ({ parcelDetail }) => {


    const StatusDetail = parcelDetail?.status;
    // console.log(StatusDetail);
    const [lastActiveIndex, setLastActiveIndex] = useState(null);


    useEffect(() => {
        const findLastActiveIndex = () => {
            if (Array.isArray(StatusDetail)) {
                for (let i = StatusDetail.length - 1; i >= 0; i--) {
                    if (StatusDetail[i].isCompleted) {
                        setLastActiveIndex(i);
                        return;
                    }
                }
            }
            setLastActiveIndex(null);
        };

        findLastActiveIndex();
    }, [StatusDetail]);

    return (
        <div className="shippment-detail-main">
            <div className="shipment-status">
                {parcelDetail?.status.map((item, index) => {
                    const nextindex = index + 1;
                    const nextData = StatusDetail[nextindex];

                    return (

                        <div key={index} className="status-details">
                            <div className="status-1">
                                <div className="status-date">{item?.date}</div>
                                <div className="status-time">{item?.time}</div>
                            </div>
                            <div className="status-2">
                                <div className={`status-line ${nextData?.isCompleted ? "completed" : ""}  ${index === lastActiveIndex ? "" : ""} `}></div>
                                <div className={`outside-circle ${item?.isCompleted ? "completed" : ""} ${lastActiveIndex === index ? "last-active-index" : ""} ${index === 0 ? "first-circle" : ""} `}><div className={`status-circle ${item?.isCompleted ? "completed" : ""} `}></div></div>
                            </div>
                            <div className="status-3">
                                <div className="status-process">{item?.process}</div>
                                <div className="status-location">{item?.location}</div>
                            </div>
                        </div>

                    )
                }

                )}
            </div>
            <ReviewEmoji />
        </div>
    );
};

export default ShipmentDetail;
