// src/components/ParcelStep/ParcelStep.js
import React, { useState, useEffect } from 'react';
import { useForm } from '../../../Contexts/FormContext/FormContext';
import ProgressBar from '../ProgressBar/ProgressBar';
import './ParcelStep.css';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';

function ParcelStep({ nextStep }) {
  const { formData, updateFormData } = useForm();

  // Initialize state with default values
  const [parcelData, setParcelData] = useState({
    parcelSize: '',
    parcelWeight: '',
    parcelContent: '',
    parcelInstructions: '',
    parcelNumber: '',
  });

  useEffect(() => {
    // Ensure formData.parcel is an object before setting state
    if (formData.parcel) {
      setParcelData({
        parcelSize: formData.parcel.parcelSize || '',
        parcelWeight: formData.parcel.parcelWeight || '',
        parcelContent: formData.parcel.parcelContent || '',
        parcelInstructions: formData.parcel.parcelInstructions || '',
        parcelNumber: formData.parcel.parcelNumber || '',
      });
    }
  }, [formData.parcel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParcelData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData('parcel', parcelData);
    nextStep('details');
  };

  return (
    <div className="place-order-container">
      <ScrollToTop />
      <ProgressBar activeStep={2} />
      <div className="place-order-bottom-container">
        <div className="container">
          <div className="form-container">
            <form className="parcel-step-form" onSubmit={handleSubmit}>
              <div className="form-section">
                <h2 className="parcel-step-heading">What is your parcel?</h2>
                <div className="parcel-size-container">
                  <div className="size-container">
                    <label className="parcel-step-label" htmlFor="parcel-size">Size of the parcel</label>
                    <input
                      className="parcel-step-input"
                      required
                      type="text"
                      id="parcel-size"
                      name="parcelSize"
                      onChange={handleChange}
                      value={parcelData.parcelSize}
                    />
                  </div>
                  <div className="size-container">
                    <label className="parcel-step-label" htmlFor="parcel-weight">Weight of the parcel</label>
                    <input
                      className="parcel-step-input"
                      required
                      type="text"
                      id="parcel-weight"
                      name="parcelWeight"
                      onChange={handleChange}
                      value={parcelData.parcelWeight}
                    />
                  </div>
                </div>
                <label className="parcel-step-label" htmlFor="parcel-content">Content of the parcel</label>
                <input
                  className="parcel-step-input"
                  required
                  type="text"
                  id="parcel-content"
                  name="parcelContent"
                  onChange={handleChange}
                  value={parcelData.parcelContent}
                />
                <label className="parcel-step-label" htmlFor="parcel-instructions">Special handling instructions</label>
                <input
                  className="parcel-step-input"
                  required
                  type="text"
                  id="parcel-instructions"
                  name="parcelInstructions"
                  onChange={handleChange}
                  value={parcelData.parcelInstructions}
                />
                <label className="parcel-step-label" htmlFor="parcel-number">Number of parcels</label>
                <input
                  className="parcel-step-input"
                  required
                  type="number"
                  id="parcel-number"
                  name="parcelNumber"
                  onChange={handleChange}
                  value={parcelData.parcelNumber}
                />
              </div>
              <div className='navigate-container'>
                <div className='navigation-container'>
                  <button className="parcel-step-type-button" type="button" onClick={() => nextStep('address')}>Back</button>
                  <button className="parcel-step-type-submit" type="submit">Save & Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParcelStep;
