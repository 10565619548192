import React from "react";
// Import SignUp CSS
import "./SignUp.css";

/* ------------- Components ------------- */
import Email from "../Email/Email";
import OTP from "../OTP/OTP";
import Password from "../Password/Password";

// UseRegisterContext Custom Hook
import { useRegisterContext } from "../../Contexts/RegisterContext/RegisterContext";

const SignUp = () => {
  // Data from UseRegisterContext Hook
  const { regData } = useRegisterContext();

  return (
    <>
      {/* Main Div */}
      <div className="signupCont">
        {/* Email */}
        {regData === "" && <Email />}

        {/* OTP */}
        {regData && !regData.verified && <OTP />}

        {/* Password */}
        {regData && regData.verified && <Password />}
      </div>
    </>
  );
};

export default SignUp;
