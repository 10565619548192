import React from "react";
import "./Guide.css";

//Cards Content
import card_content from "../guide/assets/guide";

const Guide = () => {
  return (
    <>
    {/* Main div */}
      <div className="container">
      {/* Red Section */}
        <div className="red-section">
          <div className="header">
            <h1>Steps to place the order</h1>
            <p>
              Let’s get ready with all the information going to required while
              shipping your order.
            </p>
          </div>
          {/* Cards */}
          <div className="card-container">
            {card_content.slice(0, 4).map((services, index) => (
              <div key={index} className="card">
                <h2>{services.heading}</h2>
                <p>{services.para}</p>
              </div>
            ))}
          </div>
        </div>
        {/* Lower White Section */}
        <div className="card-section">
          <div className="card-container">
            {card_content.slice(4, 8).map((services, index) => (
              <div key={index} className="card">
                <h2>{services.heading}</h2>
                <p>{services.para}</p>
              </div>
            ))}
          </div>
          {/* Button */}
          <div className="button-wrapper">
            <button>Let's go</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guide;
