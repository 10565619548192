import React, { useState } from "react";

// UseRegisterContext Custom Hook
import { useRegisterContext } from "../../Contexts/RegisterContext/RegisterContext";
// UseLoaderContext Custom Hook
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";

/* ------------- Components ------------- */
import GoogleLogin from "../../Components/GoogleLogin/GoogleLogin";

/* ------------- Fetch ------------- */
// Axios
import axios from "axios";

/* ------------- React Router Dom ------------- */
import { Link } from "react-router-dom";

/* ------------- Backend Url ------------- */
// Backend Server
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

const Email = () => {
  // Data from UseRegisterContext Hook
  const { setRegData } = useRegisterContext();

  // Data from UseLoaderContext Hook
  const { setOpenLoader } = useLoaderContext();

  // Email UseState
  const [email, setEmail] = useState("");
  // Email Alert UseState
  const [emailAlert, setEmailAlert] = useState("");

  // Request OTP Func
  const handleRequestOtp = (event) => {
    // Stop Reloading the Page when Submiting the Form
    event.preventDefault();

    // Check if the form is fill or not
    if (email && emailAlert === "") {
      // Open Loader
      setOpenLoader(true);

      // Send Verification Email API
      axios
        .post(`${BACKEND_SERVER}/user/send-email-verification`, { email })
        .then((res) => {
          //   console.log(res.data);

          // Close Loader
          setOpenLoader(false);

          // Set User Data for Verification
          setRegData(res.data);
        })
        .catch((err) => {
          //   console.log(err);

          // Close Loader
          setOpenLoader(false);
        });
    } else {
    }
  };

  // div Style CSS
  const boxStyleForm = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderBottom: "2px solid #1f1f1f",
  };

  // Alert Style CSS
  const alertStyle = {
    color: "#EA4335",
    fontSize: "14px",
    margin: "5px",
    fontWeight: "500",
  };

  return (
    <>
      <h2>Create an account</h2>
      <p>Let's get started!</p>

      {/* Email Form -> Create Account */}
      <form onSubmit={handleRequestOtp}>
        {/* Email div */}
        <div style={boxStyleForm}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
            style={{
              width: "25px",
              height: "25px",
              marginRight: "5px",
              color: "#800020",
            }}
          >
            <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
            <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
          </svg>

          {/* Email Input */}
          <input
            type="email"
            name="email"
            value={email}
            id=""
            placeholder="Email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={() => {
              if (
                !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                  email
                )
              ) {
                setEmailAlert("Invalid email address !!");
              } else {
                setEmailAlert("");
              }
            }}
          />
        </div>
        {/* Email Alert */}
        <p style={alertStyle}>{emailAlert}</p>

        {/*OTP Button */}
        <button className="button" type="submit">
          Request OTP
        </button>
      </form>

      {/* Google Login Component */}
      <GoogleLogin data={"Sign up with Google"} />

      <span
        style={{
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        Have an account?{" "}
        <Link
          to="/signin"
          style={{
            color: "#800020",
            fontWeight: "500",
            textDecoration: "none",
          }}
        >
          SignIn
        </Link>
      </span>
    </>
  );
};

export default Email;
