import React, { useState } from "react";

// UseRegisterContext Custom Hook
import { useRegisterContext } from "../../Contexts/RegisterContext/RegisterContext";
// UseLoaderContext Custom Hook
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";

/* ------------- Fetch ------------- */
// Axios
import axios from "axios";

/* ------------- Backend Url ------------- */
// Backend Server
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

const Password = () => {
  // Data from UseRegisterContext Hook
  const { regData, setRegData } = useRegisterContext();

  // Data from UseLoaderContext Hook
  const { setOpenLoader } = useLoaderContext();

  /* Passwords UseState */
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");

  /* ShowPasswords UseState */
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState("");

  // Alert UseState
  const [alert, setAlert] = useState({
    passAlert: "",
    conPassAlert: "",
  });

  // Handle Register Func
  const handleRegister = (event) => {
    // Stop Reloading the Page when Submiting the Form
    event.preventDefault();

    const data = {
      _id: regData._id,
      email: regData.email,
      password: password,
    };

    // Check if the form is fill or not
    if (
      password &&
      alert.passAlert === "" &&
      conPassword &&
      alert.conPassAlert === "" &&
      password === conPassword
    ) {
      // Open Loader
      setOpenLoader(true);

      // Registration API
      axios
        .post(`${BACKEND_SERVER}/user/register`, data)
        .then((res) => {
          // Close Loader
          setOpenLoader(false);

          setRegData("");
        })
        .catch((err) => {
          //   console.log(err);

          // Close Loader
          setOpenLoader(false);
        });
    } else {
    }
  };

  // div Style CSS
  const boxStyleForm = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    borderBottom: "2px solid #1f1f1f",
    margin: "6px 0",
  };

  // Alert Style CSS
  const alertStyle = {
    color: "#EA4335",
    fontSize: "14px",
    margin: "5px",
    fontWeight: "500",
  };

  return (
    <>
      <h2
        style={{
          marginBottom: "20px",
        }}
      >
        Create Password
      </h2>

      {/* Form Password */}
      <form onSubmit={handleRegister}>
        {/* Password div */}
        <div style={boxStyleForm}>
          <input
            type={showPassword ? "text" : "password"}
            required
            name="password"
            value={password}
            id=""
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            variant="standard"
            onBlur={() => {
              if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-=,./<>?;:'"[\]{}|~`])(?=.{8,})/.test(
                  password
                )
              ) {
                setAlert({
                  ...alert,
                  passAlert:
                    "Password must be 8+ characters with lowercase, uppercase, number, and special character.",
                });
              } else {
                setAlert({
                  ...alert,
                  passAlert: "",
                });
              }
            }}
          />

          {/* Password Icon */}
          {showPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
              onClick={() => setShowPassword(false)}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                color: "#800020",
              }}
            >
              <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              <path
                fillRule="evenodd"
                d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
              onClick={() => setShowPassword(true)}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                color: "#800020",
              }}
            >
              <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
              <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
              <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
            </svg>
          )}
        </div>
        <p style={alertStyle}>{alert.passAlert}</p>

        {/* Confirm Password div */}
        <div style={boxStyleForm}>
          <input
            type={showConPassword ? "text" : "password"}
            required
            name="conPassword"
            value={conPassword}
            id=""
            placeholder="Confirm password"
            onChange={(e) => {
              setConPassword(e.target.value);
            }}
            variant="standard"
            onBlur={() => {
              if (password !== conPassword) {
                setAlert({
                  ...alert,
                  conPassAlert: "Password not match.",
                });
              } else {
                setAlert({
                  ...alert,
                  conPassAlert: "",
                });
              }
            }}
          />

          {/* Password Icon */}
          {showConPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
              onClick={() => setShowConPassword(false)}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                color: "#800020",
              }}
            >
              <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              <path
                fillRule="evenodd"
                d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
              onClick={() => setShowConPassword(true)}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                color: "#800020",
              }}
            >
              <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
              <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
              <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
            </svg>
          )}
        </div>
        <p style={alertStyle}>{alert.conPassAlert}</p>

        <button className="button" type="submit">
          Sign Up
        </button>
      </form>
    </>
  );
};

export default Password;
