import cart_img from "../assets/cart_img.png"
import track_img from '../assets/tracking.png'
import time_img from '../assets/time_img.png'
import order_img from '../assets/order-success.png'


const track_data = [
    {
        img:cart_img,
        h3:"Enter your Order ID",
        para:"Conveniently input your order details to initiate tracking and stay updated on its status in real-time.",
    },
    {
        img:track_img,
        h3:"Track your Order",
        para:"Effortlessly monitor the progress of your shipment as it makes its journey to its destination.",
    },
    {
        img:time_img,
        h3:"Please wait, your order will arrive soon",
        para:"Rest assured as your shipment progresses toward delivery, ensuring prompt and reliable service.",
    },
    {
        img:order_img,
        h3:"Your order has successfully arrived.",
        para:"Receive notification and confirmation that your shipment has safely reached its destination, ready for pickup.",
    }
]

export default track_data;