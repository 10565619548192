import React, { useEffect, useState } from "react";
import "./NewShippingHistory.css";
import parcelimg from "./parcelimg.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_SERVER } from "../../Helper/BaseUrl";
import Cookies from "js-cookie";

const initialData = [
  {
    id: 1,
    name: "Parcel 1",
    description:
      "Whether you’re selling t-shirts or strollers, shoppers like to buy from people they trust and building trust is different based on what you are selling.",
    price: "₹100",
    image: parcelimg,
  },
  {
    id: 2,
    name: "Parcel 2",
    description: "Description 2",
    price: "₹200",
    image: parcelimg,
  },
  {
    id: 3,
    name: "Parcel 3",
    description: "Description 3",
    price: "₹300",
    image: parcelimg,
  },
  {
    id: 4,
    name: "Parcel 4",
    description: "Description 4",
    price: "₹400",
    image: parcelimg,
  },
  {
    id: 5,
    name: "Parcel 5",
    description: "Description 5",
    price: "₹500",
    image: parcelimg,
  },
  {
    id: 6,
    name: "Parcel 6",
    description: "Description 6",
    price: "₹600",
    image: parcelimg,
  },
];

const NewShippingHistory = () => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const [parcelDetail, setParcelDetail] = useState(null);

  useEffect(() => {
    const token = Cookies.get("parcelToken");
    const id = Cookies.get("parcelUserId");
    // console.log(token);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_SERVER}/parcel/get-parcel/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("Form is submitted", response.data)
        setParcelDetail(response?.data);
        // console.log(response.data)
      } catch (error) {
        console.log("Error in  ", error);
      }
    };
    fetchData();
  }, []);

  const dataToShow = showAll ? parcelDetail : parcelDetail?.slice(0, 3);

  return (
    <div className="parcel-list">
      <div className="view-all-box">
        <button
          className="view-all-button"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "View All"}
        </button>
      </div>
      {dataToShow?.map((parcel) => (
        <div key={parcel?.shipping_id} className="parcel-block">
          <img
            src={parcel?.parcelImage}
            alt=""
            className="parcel-image"
            draggable="false"
          />
          <div className="parcel-info">
            <div className="parcel-name">{parcel?.parcelContent}</div>
            <div className="parcel-description">
              <p>{parcel?.parcelInstructions}</p>
            </div>
            <div className="parcel-price">{parcel?.totalAmount}</div>
          </div>
          <button
            className="view-button"
            onClick={() => navigate(`/track-parcel/${parcel?.shipping_id}`)}
          >
            View
          </button>
        </div>
      ))}
    </div>
  );
};

export default NewShippingHistory;
