const card_content = [
    {
        heading:"Place order",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    {
        heading:"Check availability",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    {
        heading:"Continue",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    
    {
        heading:"Address Details",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    {
        heading:"Parcel Details",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    {
        heading:"Personal Details",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    {
        heading:"Payment Details",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    },
    {
        heading:"Review",
        para:"From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time."
    }
]

export default card_content;