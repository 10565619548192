import React, { useEffect, useState } from 'react';
import { useForm } from '../../../Contexts/FormContext/FormContext';
import './AddressStep.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BACKEND_SERVER } from '../../../Helper/BaseUrl';
import ProgressBar from '../ProgressBar/ProgressBar';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';

function AddressStep({ nextStep }) {
  const { formData, updateFormData } = useForm();

  const [userProfileData, setUserProfileData] = useState(null);

  useEffect(() => {
    const token = Cookies.get('parcelToken')
    const id = Cookies.get('parcelUserId')

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BACKEND_SERVER}/user/get-profile/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUserProfileData(response.data);
      } catch (error) {
        console.log("Error getting user profile", error);
      }
    }
    fetchUserData();
  }, []);

  const [addressData, setAddressData] = useState({
    fromCountry: '',
    fromCity: '',
    fromPincode: '',
    fromAddress: '',
    toCountry: '',
    toCity: '',
    toPincode: '',
    toAddress: '',
  });

  useEffect(() => {
    if (formData.address && userProfileData) {
      console.log("Hello");
      setAddressData({
        fromCountry: formData.address.fromCountry || userProfileData?.country,
        fromCity: formData.address.fromCity || userProfileData?.city,
        fromPincode: formData.address.fromPincode || userProfileData?.postalCode,
        fromAddress: formData.address.fromAddress || userProfileData?.address[0],
        toCountry: formData.address.toCountry || '',
        toCity: formData.address.toCity || '',
        toPincode: formData.address.toPincode || '',
        toAddress: formData.address.toAddress || '',
      });
    }
  }, [formData.address, userProfileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData('address', addressData);
    nextStep('parcel');
  };

  return (
    <div className="place-order-container">
      <ScrollToTop />
      <ProgressBar activeStep={1} />
      <div className="place-order-bottom-container">
        <div className="container">
          <form className="parcel-step-form" onSubmit={handleSubmit}>
            <div className="form-section">
              <h2 className="parcel-step-heading">Where are you shipping from?</h2>
              <label className="parcel-step-label" htmlFor="from-country">Country</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="from-country"
                name="fromCountry"
                onChange={handleChange}
                value={addressData.fromCountry}
              />
              <label className="parcel-step-label" htmlFor="from-city">City/town</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="from-city"
                name="fromCity"
                onChange={handleChange}
                value={addressData.fromCity}
              />
              <label className="parcel-step-label" htmlFor="from-pincode">Pincode</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="from-pincode"
                name="fromPincode"
                onChange={handleChange}
                value={addressData.fromPincode}
              />
              <label className="parcel-step-label" htmlFor="from-address">Address</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="from-address"
                name="fromAddress"
                onChange={handleChange}
                value={addressData.fromAddress}
              />
            </div>
            <div className="form-section">
              <h2 className="parcel-step-heading">Where are you shipping to?</h2>
              <label className="parcel-step-label" htmlFor="to-country">Country or city</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="to-country"
                name="toCountry"
                onChange={handleChange}
                value={addressData.toCountry}
              />
              <label className="parcel-step-label" htmlFor="to-city">City/town</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="to-city"
                name="toCity"
                onChange={handleChange}
                value={addressData.toCity}
              />
              <label className="parcel-step-label" htmlFor="to-pincode">Pincode</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="to-pincode"
                name="toPincode"
                onChange={handleChange}
                value={addressData.toPincode}
              />
              <label className="parcel-step-label" htmlFor="to-address">Address</label>
              <input
                className="parcel-step-input"
                required
                type="text"
                id="to-address"
                name="toAddress"
                onChange={handleChange}
                value={addressData.toAddress}
              />
            </div>
            <button className="parcel-step-type-submit" type="submit">Save & Continue</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddressStep;
