import React from 'react'
// AppRouter
import AppRouter from './AppRouter/AppRouter'
// Location Context Provider
import LocationContextProvider from './Contexts/LocationContext/LocationContextProvider'
// Loader Context Provider
import LoaderContextProvider from './Contexts/LoaderContext/LoaderContextProvider'
// Register Context Provider
import RegisterContextProvider from './Contexts/RegisterContext/RegisterContexProvider'
// Parcel context provider
import { FormProvider } from './Contexts/FormContext/FormContext'

const App = () => {
  return (
    // Location Context Provider Wrapper
    <LocationContextProvider>
      {/* Loader Context Provider Wrapper */}
      <LoaderContextProvider>
        {/* Register Context Provider Wrapper */}
        <RegisterContextProvider>
          {/* Parcel Context Provider Wrapper */}
          <FormProvider>
            {/* App Router */}
            <AppRouter />
          </FormProvider>
        </RegisterContextProvider>
      </LoaderContextProvider>
    </LocationContextProvider>
  )
}

export default App
