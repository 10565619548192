import React from "react";
// Register CSS
import "./Register.css";

// Image
import registerImg from "./Assets/responsive.png";

/* ------------- Components ------------- */
import Loader from "../../Components/Loader/Loader";

/* ------------- React Router Dom ------------- */
import { NavLink } from "react-router-dom";

// UseLoaderContext Custom Hook
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop";

const Register = ({ data }) => {
  // Data from UseLoaderContext Hook
  const { openLoader } = useLoaderContext();

  return (
    <>
    <ScrollToTop/>
      {/* Main Div */}
      <div className="registerPage">
        {/* Left Div */}
        <div className="leftReg">
          <img src={registerImg} alt="" />
        </div>
        {/* Right Div */}
        <div className="rightReg">
          {/* Box1 */}
          <div
            className="box"
            style={{
              backgroundColor: "#800020",
              top: "0",
            }}
          ></div>
          {/* Box2 */}
          <div
            className="box"
            style={{
              backgroundColor: "#1f1f1f",
              bottom: "0",
            }}
          ></div>

          {/* Inner Register Div */}
          <div className="innerRegister">
            {/* Navigation */}
            <div className="upNav">
              <NavLink to="/signup">Sign up</NavLink>
              <NavLink to="/signin">Sign in</NavLink>
            </div>
            {/* Nav Element */}
            {data}
          </div>
        </div>
      </div>

      {/* Loader */}
      {openLoader && <Loader />}
    </>
  );
};

export default Register;
