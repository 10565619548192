import React, { useEffect, useState } from 'react';
import './ParcelDetail.css';
// import { parcelData } from './ParcelData';
import PriceTable from './PriceTable';
import ShipmentDetail from './ShippmentDetail';
import Cookies from 'js-cookie'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_SERVER } from '../../Helper/BaseUrl';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
const ParcelDetail = () => {
    const [parcelDetail, setParcelDetail] = useState(null);
    const { sid } = useParams();
    useEffect(() => {
        const token = Cookies.get("parcelToken");
        const id = Cookies.get("parcelUserId");
        // console.log(token);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${BACKEND_SERVER}/parcel/track-parcel/${id}/${sid}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // console.log("Form is submitted", response.data)
                setParcelDetail(response?.data);
                // console.log(response.data)
            } catch (error) {
                console.log("Error in  ", error);
            }
        };

        fetchData();
    }, []);
    // console.log(parcelDetail)
    // const statusArray = parcelDetail?.status;
    // console.log(statusArray);
    return (
        <div className='parcelbg'>
            <ScrollToTop />
            <div className="parcel-details-main">
                {
                    <div className="parcel-detail">
                        <h1>Track Your Shipping</h1>
                        <h2>Shipping ID : {parcelDetail?.shipping_id}</h2>
                        <hr />
                        <div className="parcel-info">
                            <img src={parcelDetail?.parcelImage} alt="Parcel" draggable='false' />
                            <div className="parcel-content">
                                <p style={{ fontWeight: '600', fontSize: '1.5rem' }}>{parcelDetail?.parcelContent}</p>
                                <p><strong>Instructions:</strong> {parcelDetail?.parcelInstructions}</p>
                                <div className='small-detail'>
                                    <p><strong>Size:</strong> {parcelDetail?.parcelSize}</p>
                                    <p><strong>Weight:</strong> {parcelDetail?.parcelWeight}</p>
                                    <p><strong>Quantity:</strong> {parcelDetail?.parcelQuantity}</p>
                                </div>
                                <p style={{ fontWeight: '600', fontSize: '1.1rem' }}><strong style={{ fontWeight: '600', fontSize: '1.1rem' }}>Total Amount:</strong> {parcelDetail?.totalAmount}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="sender-receiver-details">
                            <div className="sender-details">
                                <h3>Sender Details</h3>
                                <p><strong>Name:</strong> {parcelDetail?.senderName}</p>
                                <p><strong>Mobile No.:</strong> {parcelDetail?.senderPhone}</p>
                                <p><strong>Email:</strong> {parcelDetail?.senderEmail}</p>
                                <p><strong>City:</strong> {parcelDetail?.senderCity}</p>
                                <p><strong>Country:</strong> {parcelDetail?.senderCountry}</p>
                                <p><strong>Pincode:</strong> {parcelDetail?.senderPincode}</p>
                                <p><strong>Address:</strong> {parcelDetail?.senderAddress}</p>
                                <p><strong>Pickup Address:</strong> {parcelDetail?.pickupAddress}</p>
                            </div>
                            <div className="receiver-details">
                                <h3>Receiver Details</h3>
                                <p><strong>Name:</strong> {parcelDetail?.receiverName}</p>
                                <p><strong>Mobile No.:</strong> {parcelDetail?.receiverPhone}</p>
                                <p><strong>Email:</strong> {parcelDetail?.receiverEmail}</p>
                                <p><strong>City:</strong> {parcelDetail?.receiverCity}</p>
                                <p><strong>Country:</strong> {parcelDetail?.receiverCountry}</p>
                                <p><strong>Pincode:</strong> {parcelDetail?.receiverPincode}</p>
                                <p><strong>Address:</strong> {parcelDetail?.receiverAddress}</p>
                                <p><strong>Dropoff Address:</strong> {parcelDetail?.dropoffAddress}</p>
                            </div>
                        </div>
                    </div>

                }


                <hr />
                <PriceTable parcelDetail={parcelDetail} />
                <hr />
                <ShipmentDetail parcelDetail={parcelDetail} />
            </div>
        </div>
    );
};

export default ParcelDetail;
