import React from "react";
// Footer CSS
import "./Footer.css";

/* ------------ React Router Dom ------------ */
import { Link } from "react-router-dom";

const Footer = () => {
  // FooterList Details
  const footerDetails = [
    {
      name: "Other links",
      data: [
        {
          name: "Blogs",
          link: "/",
        },
        {
          name: "Movers website",
          link: "/",
        },
        {
          name: "Traffic Update",
          link: "/",
        },
      ],
    },
    {
      name: "Services",
      data: [
        {
          name: "Corporate goods",
          link: "/",
        },
        {
          name: "Artworks",
          link: "/",
        },
        {
          name: "Documents",
          link: "/",
        },
      ],
    },
    {
      name: "Customer Care",
      data: [
        {
          name: "About Us",
          link: "/about",
        },
        {
          name: "Contact US",
          link: "/contact",
        },
        {
          name: "Get Update",
          link: "/",
        },
      ],
    },
  ];

  return (
    <>
      {/* Main Footer */}
      <div className="footer">
        {/* Upper Footer */}
        <div className="upFoot">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            {/* Left */}
            <div className="left">
              <h2>Get an update every week</h2>
              <p
                style={{
                  fontSize: "0.9rem",
                  margin: "10px 0",
                }}
              >
                We ensure that your product is delivered in the safest possible
                manner, at the correct location, at the right time.
              </p>
            </div>
            {/* Right */}
            <div className="right">
              <p
                style={{
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Subscribe to our Newsletter
              </p>
              {/* Subscribe Box */}
              <div className="subscribe">
                <input type="text" placeholder="Enter your Email" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </div>

        {/* Middle Footer */}
        <div className="middleFoot">
          {/* Sizebox for Large screen responsive */}
          <div className="sizeBox">
            {/* Box 1 */}
            <div className="box1">
              <p
                style={{
                  fontSize: "0.8rem",
                }}
              >
                The most trusted Courier company in your area.
              </p>
            </div>

            {/* Boxes */}
            {footerDetails.map((f, i) => (
              <div className="box" key={i}>
                <h3
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  {f.name}
                </h3>
                {f.data.map((fd, fi) => (
                  <Link key={fi} to={fd.link}>
                    {fd.name}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* Down Footer */}
        <div className="downFoot">
          {/* Sizebox for Large screen responsive */}
          <div
            className="sizeBox"
            style={{
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: "100%",
                margin: "15px",
                textAlign: "center",
                alignSelf: "center",
                color: "#d4d4d4",
                fontSize: "0.9rem",
              }}
            >
              All rights Reserved © ApnaParcel, 2024
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
