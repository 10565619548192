
import React, { createContext, useState, useContext } from 'react';

const FormContext = createContext();

export const useForm = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    address: {},
    parcel: {},
    details: {},
    amount: {
      shippingAmount: 1000,
      pickupAmount: 150,
      taxAmount: 100,
      totalAmount: 1250
    }
  });

  const updateFormData = (step, data) => {
    setFormData(prevState => ({
      ...prevState,
      [step]: data,
    }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};
