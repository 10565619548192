import React from "react";
import "./PriceTable.css";

const PriceTable = ({ parcelDetail }) => {
  return (
    <div className="price-detail-table">
      <h1>Price Details</h1>
      <table>
        <tbody>
          <tr>
            <td>Shipping Amount</td>
            <td>₹{parcelDetail?.shippingAmount.toFixed(2)}</td>
          </tr>
          <tr>
            <td>PickUp Amount</td>
            <td>₹{parcelDetail?.pickupAmount.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Tax Amount</td>
            <td>₹{parcelDetail?.taxAmount.toFixed(2)}</td>
          </tr>

          <tr className="total">
            <td>Total</td>
            <td>₹{parcelDetail?.totalAmount.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PriceTable;
