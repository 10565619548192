import React, { useState } from 'react';
import './ReviewEmoji.css';
import { BsEmojiFrownFill, BsEmojiNeutralFill, BsEmojiSmileFill } from "react-icons/bs";
import { MdEmojiEmotions } from "react-icons/md";
import { FaGrinStars } from "react-icons/fa";

const ReviewEmoji = () => {
  const [selectedRating, setSelectedRating] = useState(null);

  const handleRatingClick = (rating) => {
    setSelectedRating(rating);
  };

  return (
    <div className="review-rating-main">
      <h1>How was your Shipping experience?</h1>
      <div className='review-rating'>

        <div
          className={`rating-item ${selectedRating === 'bad' ? 'selected' : ''}`}
          onClick={() => handleRatingClick('bad')}
        >
          <BsEmojiFrownFill className="icon bad" />
          <span>Bad</span>
        </div>
        <div
          className={`rating-item ${selectedRating === 'ok' ? 'selected' : ''}`}
          onClick={() => handleRatingClick('ok')}
        >
          <BsEmojiNeutralFill className="icon ok" />
          <span>Ok</span>
        </div>
        <div
          className={`rating-item ${selectedRating === 'average' ? 'selected' : ''}`}
          onClick={() => handleRatingClick('average')}
        >
          <BsEmojiSmileFill className="icon average" />
          <span>Average</span>
        </div>
        <div
          className={`rating-item ${selectedRating === 'good' ? 'selected' : ''}`}
          onClick={() => handleRatingClick('good')}
        >
          <MdEmojiEmotions className="icon good" style={{ fontSize: '2.3rem' }} />
          <span>Good</span>
        </div>
        <div
          className={`rating-item ${selectedRating === 'best' ? 'selected' : ''}`}
          onClick={() => handleRatingClick('best')}
        >
          <FaGrinStars className="icon best" />
          <span>Best</span>
        </div>
      </div>
    </div>
  );
};

export default ReviewEmoji;
