import React, {useState} from 'react'
// import ParcelMan from './parcel-man.png'
import ParcelManNew from './parcel.png'
import Mail from './ReadMail.png'
import Icon from './icon.png'
import Map from './MapPinLine-r.png'
import Phone from './PhoneCall-r.png'
import icon1 from './icon0.png'
import icon2 from './icon1.png'
import icon3 from './icon2.png'
import icon4 from './icon3.png'
import './Contact.css'
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

import { BACKEND_SERVER } from "../../Helper/BaseUrl";

const Contact = () => {

  
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [msg, setMsg] = useState('');
const [feedback, setFeedback] = useState(null);

const handleSubmit= async (e)=>{
  e.preventDefault();
  if(name ==='' || email ===''){
    alert("kindly fill the details");
  }
  await axios.post(`${BACKEND_SERVER}/feedback/create-feedback`, {
    name,
    email,
    msg,
  }).then((res)=>{
    setFeedback(res.data);
    // alert("Your data saved successfully !! we will contact you soon.");
    toast.success('Your data saved successfully !! We will contct you soon.', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    console.log(res.data);
    setName('');
    setEmail('');
    setMsg('');
  }).catch((err)=>{
    console.log(err);
    toast.error('Something went wrong !! Try again after some time.', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  })
}

const showFeedback=()=>{
  axios.get(`${BACKEND_SERVER}/feedback/get-all-feedback`).then((res)=>{
    setFeedback(res.data);
  })
};


  return (
    <div className='parent'>
      <ScrollToTop/>
      <div className='upper-sec'>
      <h1>Get in Touch<br/> with Us</h1>
      </div>
        <div className='upper-color'>
        <div className='right'>
            <img src={ParcelManNew} alt='parcel-man'></img>
        </div>
        <div className='left'>
            <p>From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time.</p>
        </div>
      </div>
      <div className='form-sec'>
        <div className='form-left'>
            <h1>Send a Message</h1>
            <div className='icons'>
                <img src={icon1} alt='facebook'></img>
                <img src={icon2} alt='facebook'></img>
                <img src={icon3} alt='facebook'></img>
                <img src={icon4} alt='facebook'></img>
            </div>
        </div>
        <div className='form-right'>
            <form onSubmit={handleSubmit}>
                <input type='text' name='name' value={name} onChange={(e)=>{setName(e.target.value);}} placeholder='Name' required/>
                <input type='email' name='email' value={email} onChange={(e)=>{setEmail(e.target.value);}} placeholder='Email' required />
                <textarea placeholder='msg' value={msg} onChange={(e)=>{setMsg(e.target.value);}} required></textarea>
                <button type='submit'>Give a Direction</button>
            </form>
        </div>
        <div className='res-icons'>
        <img src={icon1} alt='facebook'></img>
                <img src={icon2} alt='facebook'></img>
                <img src={icon3} alt='facebook'></img>
                <img src={icon4} alt='facebook'></img>
            </div>
      </div>
      <div className='boxes-sec'>
        <div className='box box1'>
            <div className='img'>
              <div className='ellipse'></div>
              <img src={Mail} alt='mail'></img>
            </div>
            <div className='text'>
               <h2>Email</h2>
               <p>Apnaparcel@gmail.com</p>
               <p>Apnaparcel@gmail.com</p>
            </div>
        </div>
        <div className='box box1'>
        <div className='img'>
              <div className='ellipse'></div>
              <img src={Phone} alt='mail'></img>
            </div>
            <div className='text'>
               <h2>Phone</h2>
               <p>+91 9873678458</p>
               <p>+91 9873678458</p>
            </div>
        </div>
        <div className='box box1'>
        <div className='img'>
              <div className='ellipse'></div>
              <img src={Map} alt='mail'></img>
            </div>
            <div className='text'>
               <h2>Address</h2>
               <p>245 ajhksgh djjjjgd </p>
            </div>
        </div>
        </div>
        <div className='large-box'>
          <div className='text-sec'>
            <h1>Visit our Office or warehouse</h1>
            <p>From local deliveries to international shipments, we offer efficient, reliable, and cost-effective options to ensure your packages reach their destination safely and on time.</p>
            <button>Give a Direction</button>
          </div>
          <div className='box-sec'>
            <h2>Hours</h2>
            <div className='text'>
              <h3>Monday to Friday</h3>
              <p>9 am to 5 pm</p>
            </div>
            <div className='text'>
              <h3>Saturdays</h3>
              <p>9 am to 5 pm</p>
            </div>
            <div className='text'>
              <h3>Sundays</h3>
              <p>9 am to 5 pm</p>
            </div>
          </div>
        </div>
        <h1 className='faq-heading'>Frequently Asked Question</h1>
        <div className='faq'>
          <div className='faq-text'>
            <div className='faq-textarea'>
              <img src={Icon} alt='question'></img>
              <p>How can i become a partner with BPS?</p>
            </div>
            <div className='faq-textarea'>
              <img src={Icon} alt='question'></img>
              <p>How do i schedule a delivery?</p>
            </div>
            <div className='faq-textarea'>
              <img src={Icon} alt='question'></img>
              <p>do you operate 24/7?</p>
            </div>
            <div className='faq-textarea'>
              <img src={Icon} alt='question'></img>
              <p>cann i track my orders?</p>
            </div>
          </div>
          <div className='faq-form'>
            <h1>Ask a Different Question</h1>
            <form onSubmit={handleSubmit}>
            <div> 
            <input type='text' name='name' value={name} onChange={(e)=>{setName(e.target.value);}} placeholder='Name' required/>
            <hr></hr>
            </div>
            <div>
            <input type='text' name='email' value={email} onChange={(e)=>{setEmail(e.target.value);}} placeholder='Email address' required/>
            <hr></hr>
            </div>
            <div>
            <input type='text' name='msg' value={msg} onChange={(e)=>{setMsg(e.target.value);}} placeholder='Message' style={{marginTop: 32}} required/>
            <hr></hr>
            </div>
            <button type='submit'>Submit</button>
            </form>
          </div>
        </div>
    </div>
  )
}

export default Contact
