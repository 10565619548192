import React from 'react'
import Guide from './guide/Guide'
import Track from './Track/Track'
import './Faq.css'
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop'

const Faq = () => {
  return (
    <>
      <ScrollToTop />
      <div className='faq-container'>
      <Guide />
      <Track />
      </div>
      
    </>
  );
};

export default Faq;
