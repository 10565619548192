import React, { useState } from 'react';
import AddressStep from '../AddressStep/AddressStep';
import ParcelStep from '../ParcelStep/ParcelStep';
import DetailsStep from '../DetailsStep/DetailsStep';
import ReviewStep from '../ReviewStep/ReviewStep';
import PaymentStep from '../PaymentStep/PaymentStep';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';

function OrderParcel() {
  const [currentStep, setCurrentStep] = useState('address');

  const nextStep = (step) => {
    setCurrentStep(step);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'address':
        return <AddressStep nextStep={nextStep} />;
      case 'parcel':
        return <ParcelStep nextStep={nextStep} />;
      case 'details':
        return <DetailsStep nextStep={nextStep} />;
      case 'review':
        return <ReviewStep nextStep={nextStep} />;
      case 'payment':
        return <PaymentStep nextStep={nextStep} />;
      default:
        return <AddressStep nextStep={nextStep} />;
    }
  };

  return (
    <div>
      <ScrollToTop />
      {renderStep()}
    </div>
  );
}

export default OrderParcel;
