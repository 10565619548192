import React from "react";

// Google Logo
import googleImg from "./Assets/google.png";

/* ------------- Backend Url ------------- */
// Backend Server
import { BACKEND_SERVER } from "../../Helper/BaseUrl";

const GoogleLogin = ({ data }) => {
  // Main Style
  const mainStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <>
      {/* Main Div */}
      <div className="googleLogin" style={mainStyle}>
        {/* Button */}
        <button
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "none",
            borderRadius: "5px",
            padding: "9px",
            cursor: "pointer",
            backgroundColor: "#F1F1F1",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
          onClick={() => {
            window.location.href = `${BACKEND_SERVER}/user/auth/google/callback`;
          }}
        >
          {/* Google Logo */}
          <img
            src={googleImg}
            alt="google"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
          />
          {/* Text */}
          <span
            style={{
              fontSize: "1.1rem",
              fontWeight: "500",
              color: "black",
            }}
          >
            {data}
          </span>
        </button>
      </div>
    </>
  );
};

export default GoogleLogin;
