import React, { useEffect, useState } from "react";
// TrackPlaceBox CSS
import "./TrackPlaceBox.css";

// Truck Image
import truck from "./Assets/truck.png";

// Cookies
import Cookies from "js-cookie";

// useLocationContext Custom Hook
import { useLocationContext } from "../../Contexts/LocationContext/LocationContext";
import { useNavigate } from "react-router-dom";

const TrackPlaceBox = () => {
  const navigate = useNavigate();

  // Postcode Context
  const { postcode } = useLocationContext();

  // Tracking UseState
  const [trackId, setTrackId] = useState("");

  // Val UseState for Navigation
  const [val, setVal] = useState(false);

  // TrackSubmit Func
  const handleTrackSubmit = (event) => {
    event.preventDefault();
    const authed = Cookies.get("parcelToken");
    if (authed) {
      if (trackId !== "") {
        navigate(`/track-parcel/${trackId}`);
      }
    } else {
      navigate("/signin");
    }
  };

  // Parcel Pin Code State
  const [parcel, setParcel] = useState({
    sender: "",
    receiver: "",
  });

  // Handle Parcel Change Func
  const handleParcelChange = (e) => {
    const { name, value } = e.target;

    if (isNaN(value)) {
      // If not a number, prevent further action
      return;
    }

    setParcel({
      ...parcel,
      [name]: value,
    });
  };

  // Animation Stage State
  const [animationStage, setAnimationStage] = useState("");

  // Sender Focus Func
  const handleSenderFocus = () => {
    setAnimationStage("move-to-first");
  };

  // Receiver Focus Func
  const handleReceiverFocus = () => {
    if (parcel.sender !== "") {
      setAnimationStage("move-to-end");
    }
  };

  // Order Place Func
  const handleParcelOrder = (event) => {
    event.preventDefault();
    const authed = Cookies.get("parcelToken");

    if (authed) {
      if (parcel.sender !== "" && parcel.receiver !== "") {
        navigate(`/order-parcel`);
      }
    } else {
      navigate("/signin");
    }
  };

  // Fetching Pincode
  useEffect(() => {
    const postCode = Cookies.get("postcode");
    setParcel({
      ...parcel,
      sender: postcode ? postcode : postCode,
    });
  }, [postcode]);

  return (
    <>
      {/* Main Box */}
      <div className="trackPlaceCont">
        {/* Up Box */}
        <div className="up">
          {/* Track Nav */}
          <div
            className="box"
            style={{
              backgroundColor: val ? "#800020" : "#fff",
              color: val ? "#fff" : "rgb(17, 17, 17)",
            }}
            onClick={() => {
              setVal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
              style={{
                width: "25px",
                height: "25px",
                marginBottom: "10px",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>
            Track
          </div>
          {/* Place Order Nav */}
          <div
            className="box"
            style={{
              backgroundColor: val ? "#fff" : "#800020",
              color: val ? "rgb(17, 17, 17)" : "#fff",
            }}
            onClick={() => {
              setVal(false);
            }}
          >
            <svg
              data-slot="icon"
              fill="none"
              strokeWidth="1.5"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              style={{
                width: "25px",
                height: "25px",
                marginBottom: "10px",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
              ></path>
            </svg>
            Place Order
          </div>
        </div>

        {/* If Val is true then open Track Box */}
        {val ? (
          <div className="track">
            {/* Form */}
            <form onSubmit={handleTrackSubmit}>
              <p>Tracking ID</p>
              <input
                type="text"
                placeholder="Enter tracking id"
                value={trackId}
                onChange={(e) => {
                  setTrackId(e.target.value);
                }}
              />
              <button type="submit">Track</button>
            </form>
          </div>
        ) : (
          // If te track is false then open ship box
          <div className="ship">
            {/* Form */}
            <form onSubmit={handleParcelOrder}>
              <h2>
                Ship <strong>Apna Parcel</strong>
              </h2>
              {/* Inner Box */}
              <div className="innerBox">
                {/* Tracking UI */}
                <div className="left">
                  {/* Up Circle */}
                  <div
                    className="r"
                    style={{
                      backgroundColor:
                        parcel.sender !== "" ? "#800020" : "#fff",
                    }}
                  >
                    {/* Truck Image */}
                    <img
                      src={truck}
                      alt=""
                      id="movingImage"
                      className={animationStage}
                      draggable="false"
                    />
                  </div>
                  <hr />
                  {/* Down Circle */}
                  <div
                    className="r"
                    style={{
                      backgroundColor:
                        parcel.receiver !== "" ? "#800020" : "#fff",
                    }}
                  ></div>
                </div>

                {/* Right form Box */}
                <div className="right">
                  <input
                    type="text"
                    name="sender"
                    placeholder="Enter sender pin code"
                    value={parcel.sender}
                    onChange={handleParcelChange}
                    onFocus={handleSenderFocus}
                  />
                  <input
                    type="text"
                    name="receiver"
                    placeholder="Enter receiver pin code"
                    value={parcel.receiver}
                    onChange={handleParcelChange}
                    onFocus={handleReceiverFocus}
                    readOnly={parcel.sender === ""}
                  />
                </div>
              </div>
              <button type="submit">Find</button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default TrackPlaceBox;
