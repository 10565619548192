import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import { Link, useNavigate } from "react-router-dom";
import "./ProfileCompletion.css";
import axios from "axios";
import { Bounce, toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successimg from "./successapna.png";
import { IoMdAddCircleOutline } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import Cookies from "js-cookie";
import { BACKEND_SERVER } from "../../Helper/BaseUrl";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useLoaderContext } from "../../Contexts/LoaderContext/LoaderContext";
import Loader from "../Loader/Loader";

const MySwal = withReactContent(Swal);

const countryCodes = [
  { countryCode: "+1", country: "United States" },
  { countryCode: "+44", country: "United Kingdom" },
  { countryCode: "+91", country: "India" },
  // Add more country codes as needed
];

const EditProfile = () => {
  const navigate = useNavigate();
  // Data from UseLoaderContext Hook
  const { openLoader, setOpenLoader } = useLoaderContext();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    businessName: "",
    country: "",
    address: [""],
    postalCode: "",
    state: "",
    city: "",
    email: "",
    countryCode: "",
    phoneNo: "",
  });

  const [isConfirming, setIsConfirming] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNo" && isNaN(value)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddressChange = (index, value) => {
    const newAddresses = [...formData.address];
    newAddresses[index] = value;
    setFormData({
      ...formData,
      address: newAddresses,
    });
  };
  // to add address field
  const addAddressField = () => {
    setFormData({
      ...formData,
      address: [...formData.address, ""],
    });
  };
  // to remove address field
  const removeAddressField = (index) => {
    const newAddresses = formData.address.filter(
      (_, addrIndex) => addrIndex !== index
    );
    setFormData({
      ...formData,
      address: newAddresses,
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();

    // check for required fields
    const requiredFields = [
      "firstname",
      "lastname",
      "country",
      "address",
      "postalCode",
      "state",
      "city",
      "email",
      "countryCode",
      "phoneNo",
    ];
    const emptyFields = requiredFields.filter(
      (field) =>
        !formData[field] ||
        (Array.isArray(formData[field]) &&
          formData[field].some((addr) => !addr))
    );

    if (emptyFields.length > 0) {
      toast.error("Kindly fill all the required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } else {
      setIsConfirming(true);
    }
  };
  // console.log(formData);
  const handleBack = () => {
    setIsConfirming(false);
  };
  useEffect(() => {
    const token = Cookies.get("parcelToken");
    const id = Cookies.get("parcelUserId");

    setOpenLoader(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_SERVER}/user/get-profile/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("Form is submitted", response.data)
        setFormData({
          ...formData,
          ...response?.data,
          firstname: response?.data?.name?.split(" ")[0],
          lastname: response?.data?.name?.split(" ")[1],
          address:
            response?.data?.address?.length === 0
              ? [""]
              : response?.data?.address,
        });

        setOpenLoader(false);
      } catch (error) {
        console.log("Error in Submission", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = Cookies.get("parcelUserId");
    const token = Cookies.get("parcelToken");
    setOpenLoader(true);
    try {
      const response = await axios.put(
        `${BACKEND_SERVER}/user/edit-profile/${id}`,
        { ...formData, name: formData.firstname + " " + formData.lastname },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Form is submitted", response.data);
      MySwal.fire({
        title: "Great! You have done",
        // html: '<div style="font-size: 14px;">Form submitted successfully</div>',
        // text: 'Form submitted successfully',
        imageUrl: `${successimg}`,
        imageHeight: 120,
        imageWidth: 120,
        showCloseButton: true,
        confirmButtonText: "OK",
        // customClass: {
        //     confirmButton: 'custom-ok-button-class' // Add custom class to the OK button
        // }
      }).then(() => {
        navigate("/profile");
      });

      setOpenLoader(false);
    } catch (error) {
      console.log("Error in Submission", error);
    }
  };

  return (
    <div>
      <ScrollToTop />
      {isConfirming ? (
        <div className="placeorder-completion">
          <div className="heading">
            <h1>Review all the information and complete the process</h1>
          </div>
          <div className="order-active">
            <div className="contact-detail-btn-sub">
              <div className="contact-detail-btn"></div>
              <h3>Contact Details</h3>
            </div>
            <div className="review-complete-btn-sub">
              <div className="review-complete-btn"></div>
              <h3>Review and Complete</h3>
            </div>
          </div>

          <div className="contact-details ">
            <h3>Contact Details</h3>
            <div className="completion-detail-main">
              <h4>First name :</h4>
              <div className="completion-detail-div">{formData.firstname}</div>
            </div>
            <div className="completion-detail-main">
              <h4>Last name :</h4>
              <div className="completion-detail-div"> {formData.lastname} </div>
            </div>
            <div className="completion-detail-main">
              <h4>Business name :</h4>
              <div className="completion-detail-div">
                {formData.businessName}{" "}
              </div>
            </div>
            <div className="completion-detail-main">
              <h4>Country/Territory :</h4>
              <div className="completion-detail-div">{formData.country} </div>
            </div>
            <div className="completion-detail-main">
              <h4>Address :</h4>
              <div className="address-detail">
                {formData.address.map((addr, index) => (
                  <div key={index} className="completion-detail-div">
                    {addr}
                  </div>
                ))}
              </div>
            </div>
            <div className="completion-detail-main">
              <h4>Postal code :</h4>
              <div className="completion-detail-div">
                {formData.postalCode}{" "}
              </div>
            </div>
            <div className="completion-detail-main">
              <h4>City :</h4>
              <div className="completion-detail-div">{formData.city} </div>
            </div>
            <div className="completion-detail-main">
              <h4>State :</h4>
              <div className="completion-detail-div">{formData.state}</div>
            </div>
            <div className="completion-detail-main">
              <h4>Phone no. :</h4>
              <div className="completion-detail-div">{formData.phoneNo} </div>
            </div>
            <div className="order-btn">
              <button type="button" onClick={handleBack}>
                Back
              </button>
              <button type="button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-main">
          <div className="order-heading">
            <h1>Update Your Profile</h1>
            <div className="order-active">
              <div className="contact-detail-btn-sub">
                <div className="contact-detail-btn"></div>
                <h3>Contact Details</h3>
              </div>
              <div className="review-complete-btn-sub">
                <div className="review-complete-btn"></div>
                <h3>Review and Complete</h3>
              </div>
            </div>
          </div>
          <div className="order-form-container">
            <form onSubmit={handleContinue}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstname">First Name *</label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">Last Name *</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="businessName">Business Name</label>
                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country/territory *</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
              {formData.address.map((address, index) => (
                <div key={index} className="form-group">
                  <label htmlFor={`address${index}`}>
                    Address{index + 1} {index === 0 && "*"}
                  </label>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="text"
                      id={`address${index}`}
                      name={`address${index}`}
                      value={address}
                      onChange={(e) =>
                        handleAddressChange(index, e.target.value)
                      }
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeAddressField(index)}
                        className="remove-address-btn"
                        style={{
                          border: "none",
                          outline: "none",
                          background: "none",
                        }}
                      >
                        <GrSubtractCircle size={17} />
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                className="add-more"
                type="button"
                onClick={addAddressField}
              >
                <IoMdAddCircleOutline size={20} />
              </button>

              <div className="form-row">
                <div className="form-group" style={{ flex: "0 0 30%" }}>
                  <label htmlFor="postalcode">Postal Code *</label>
                  <input
                    type="number"
                    id="postalcode"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group" style={{ flex: "0 0 70%" }}>
                  <label htmlFor="state">State *</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="city">City *</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group" style={{ flex: "0 0 30%" }}>
                  <label htmlFor="countryCode">Country Code *</label>
                  <select
                    id="countrycode"
                    name="countryCode"
                    className="country-code"
                    value={formData.countryCode}
                    onChange={handleChange}
                  >
                    <option value="">Select Country Code</option>
                    {countryCodes.map((code, index) => (
                      <option key={index} value={code.countryCode}>
                        {code.country} ({code.countryCode})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group" style={{ flex: "0 0 70%" }}>
                  <label htmlFor="phone">Phone *</label>
                  <input
                    type="text"
                    id="phone"
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="order-btn">
                <button type="submit">Continue</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Loader */}
      {openLoader && <Loader />}
    </div>
  );
};

export default EditProfile;
