import React, { useState } from "react";

// Register Context Hook
import { RegisterContext } from "./RegisterContext";

const RegisterContextProvider = ({ children }) => {
  // Register Data UseState
  const [regData, setRegData] = useState("");

  return (
    // RegisterContext Provider
    <RegisterContext.Provider value={{ regData, setRegData }}>
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterContextProvider;
