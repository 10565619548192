import location from "./Assets/Location.png";
import cart from "./Assets/Buy.png";
import graph from "./Assets/Graph.png";
import business from "./Assets/business.svg";
import statewide from "./Assets/statewide.svg";
import personal from "./Assets/personal.svg";

import shield from "./Assets/Shield Done.png";
import discovery from "./Assets/Discovery.png";
import star from "./Assets/Star.png";

import airbnb from "./Assets/airbnb.png";
import amazon from "./Assets/amazon.png";
import stripe from "./Assets/stripe.png";
import hubspot from "./Assets/hubspot.png";
import dribble from "./Assets/dribble.png";
import shopify from "./Assets/shopify.png";
import carta from "./Assets/carta.png";

const thirdHomeContent = [
    {
        img: location,
        para: "Find Location"
    },
    {
        img: cart,
        para: "Schedule Pickup"
    },
    {
        img: graph,
        para: "Redirect Package"
    }
]

const fifthHomeContent = [
    {
        img: business,
        para: "Business Services",
        details: "We give you complete reliable delivery for your company. We will take full responsibility of the deliveries.",
        item: [
            "Corporate goods",
            "Shipment",
            "Accesories"
        ],
        btn: "Learn more"
    },
    {
        img: statewide,
        para: "Statewide Services",
        details: "Offering home delivery around the city, where your products will be at your doorstep within 48-72 hours.",
        item: [
            "Personal items",
            "Percels",
            "Documents"
        ],
        btn: "Learn more"
    },
    {
        img: personal,
        para: "Personal Services",
        details: "You can trust us to safely deliver your most sensitive documents to the specific area in a short time.",
        item: [
            "Gifts",
            "Package",
            "Documents"
        ],
        btn: "Learn more"
    }
]

const sixthHomeContent = [
    {
        data: "95%+",
        para: "On- Time delivery rate",
    },
    {
        data: "2600+",
        para: "Delivery per month",
    },
    {
        data: "2M+",
        para: "Happy Customers",
    },
    {
        data: "5- Star",
        para: "Ratings from the Clients",
    }
]

const seventhHomeContent = [
    {
        img: discovery,
        heading: "Reliability",
        para: "Our courier service is known for its unwavering commitment to reliability. We ensure that your packages are delivered on time, every time, without fail.",
    },
    {
        img: shield,
        heading: "Speed",
        para: "Need a package delivered urgently? Count on us for lightning-fast delivery services. We prioritize speed without compromising on the safety and security of your shipments.",
    },
    {
        img: star,
        heading: "Affordable Rates",
        para: "Say goodbye to exorbitant shipping fees! We offer competitive and transparent pricing, making our courier services accessible to businesses and individuals alike.",
    },
    {
        img: shield,
        heading: "Service",
        para: "Our courier service is known for its unwavering commitment to reliability. We ensure that your packages are delivered on time, every time, without fail.",
    }
]

const eighthHomeContent = [
    {
        img: airbnb,
    },
    {
        img: amazon,
    },
    {
        img: stripe,
    },
    {
        img: hubspot,
    },
    {
        img: dribble,
    },
    {
        img: shopify,
    },
    {
        img: carta,
    },
];

export { thirdHomeContent, fifthHomeContent, sixthHomeContent, seventhHomeContent, eighthHomeContent }